import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import ReactPlayer from 'react-player'
export default function Dialougebox() {
  const [open, setOpen] = React.useState(false)
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('xl'))

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <button
        className='bg-trans border-0 zoom-in-zoom-out ml-2'
        onClick={handleClickOpen}
      >
        <i class='fas fa-play text-success p-3 btn1 btn-animated'></i>
      </button>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby='responsive-dialog-title'
        className='bg-trans no-shadow'
      >
        <div className='row mx-auto p-2 p-xl-3 w-100'>
          <div className='ml-auto'>
            <Button className='ml-auto' autoFocus onClick={handleClose}>
              <i className='fa fa-lg fa-times text-white white-hover border border-light rounded-circle p-2'></i>
            </Button>
          </div>
        </div>
        <DialogContent className='text-center d-flex'>
          <ReactPlayer
            className='m-auto'
            url='https://www.youtube.com/watch?v=ysz5S6PUM-U'
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}
