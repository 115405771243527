import Header from "../shared/Header";
import WhyUs from "../small/homesection/WhyUs";
import ScrollAnimation from "react-animate-on-scroll";
import Footer from "../shared/Footer";
import Scrolltop from "../shared/Scrolltop";
import MainBanner from "../small/aboutsections/MainBanner";
import MenuBar from "../small/aboutsections/MenuBar";
import StickyHeader from "../shared/StickyHeader";
import Services from "../small/aboutsections/Services";
import OurStory from "../small/aboutsections/OurStory";
import Techtext from "../shared/Techtext";
import Journey from "../small/aboutsections/Journey";
import CoreValues from "../small/aboutsections/CoreValues";
import Whatwedo from "../small/aboutsections/Whatwedo";
import OurHistory from "../small/aboutsections/OurHistory";
import React, { Component } from "react";
import OurValues from "../small/aboutsections/OurValues";
import Technologies from "../small/aboutsections/Technologies";
import Joining from "../small/aboutsections/Joining";
import JoiningProccess from "../small/aboutsections/JoiningProccess";
import JoinTeam from "../small/aboutsections/JoinTeam";
import ApplyVanacy from "../small/aboutsections/ApplyVanacy";
import OurPeople from "../small/aboutsections/OurPeople";
import Faqs from "../small/aboutsections/Faqs";

class About extends Component {
  state = {
    step: 1,
    platform: "",
    storeLocation: "",
    flag: "",
    location: "",
    api: "",
    extension: "",
    user: "",
    list: "",
    mbWidth:window.innerWidth,
  };

  ChangeStep = (current_step) => {
    this.setState({ step: current_step });
  };
  
  render() {
    const { step ,mbWidth} = this.state;
    console.log(mbWidth,"current width")
    switch (step) {
      case 1:
        return (
          <div >
          {mbWidth<1050?
          (
            <div>
   <Scrolltop />
        <Header />
        <StickyHeader />
        <ScrollAnimation
          delay={500}
          animateIn="animate__fadeInLeft"
          animateOnce={true}
        >
          <MainBanner />
        </ScrollAnimation>
      
        <Services />
        <OurStory />
        <WhyUs />
        <Journey />
        <Techtext text="Contact Us Now" />
        <CoreValues />
        <Whatwedo />
        <OurHistory />
        <Technologies />
            <Joining />
            <JoiningProccess />
            <JoinTeam />
            <ApplyVanacy />
            <OurPeople />
            <Faqs />
        <Footer />
            </div>
          ):
          <div>
<Scrolltop />
            <Header />
            <StickyHeader />
            <ScrollAnimation
              delay={500}
              animateIn="animate__fadeInLeft"
              animateOnce={true}
            >
              <MainBanner />
            </ScrollAnimation>
            <MenuBar ChangeStep={this.ChangeStep} step={step} />
            <Services />
            <OurStory />
            <Footer />
          </div>
          }
            
          </div>
        );
      case 2:
        return (
          <>
            <Scrolltop />
            <Header />
            <StickyHeader />
            <ScrollAnimation
              delay={500}
              animateIn="animate__fadeInLeft"
              animateOnce={true}
            >
              <MainBanner />
            </ScrollAnimation>
            <MenuBar ChangeStep={this.ChangeStep} step={step} />
            <WhyUs />
            <Journey />
            <Techtext text="Contact Us Now" />
            <Footer />
          </>
        );
      case 3:
        return (
          <>
            <Scrolltop />
            <Header />
            <StickyHeader />
            <ScrollAnimation
              delay={500}
              animateIn="animate__fadeInLeft"
              animateOnce={true}
            >
              <MainBanner />
            </ScrollAnimation>
            <MenuBar ChangeStep={this.ChangeStep} step={step} />
            <CoreValues />
            <Whatwedo />
            <OurValues />
            <Footer />
          </>
        );
      case 4:
        return (
          <>
            <Scrolltop />
            <Header />
            <StickyHeader />
            <ScrollAnimation
              delay={500}
              animateIn="animate__fadeInLeft"
              animateOnce={true}
            >
              <MainBanner />
            </ScrollAnimation>
            <MenuBar ChangeStep={this.ChangeStep} step={step} />
            <OurHistory />
            <Footer />
          </>
        );

      case 5:
        return (
          <>
            <Scrolltop />
            <Header />
            <StickyHeader />
            <ScrollAnimation
              delay={500}
              animateIn="animate__fadeInLeft"
              animateOnce={true}
            >
              <MainBanner />
            </ScrollAnimation>
            <MenuBar ChangeStep={this.ChangeStep} step={step} />
            <Technologies />
            <Footer />
          </>
        );

      case 6:
        return (
          <>
            <Scrolltop />
            <Header />
            <StickyHeader />
            <ScrollAnimation
              delay={500}
              animateIn="animate__fadeInLeft"
              animateOnce={true}
            >
              <MainBanner />
            </ScrollAnimation>
            <MenuBar ChangeStep={this.ChangeStep} step={step} />
            <Joining />
            <JoiningProccess />
            <JoinTeam />
            <ApplyVanacy />
            <Footer />
          </>
        );

      case 7:
        return (
          <>
            <Scrolltop />
            <Header />
            <StickyHeader />
            <ScrollAnimation
              delay={500}
              animateIn="animate__fadeInLeft"
              animateOnce={true}
            >
              <MainBanner />
            </ScrollAnimation>
            <MenuBar ChangeStep={this.ChangeStep} step={step} />
            <OurPeople />
            <Footer />
          </>
        );

      case 8:
        return (
          <>
            <Scrolltop />
            <Header />
            <StickyHeader />
            <ScrollAnimation
              delay={500}
              animateIn="animate__fadeInLeft"
              animateOnce={true}
            >
              <MainBanner />
            </ScrollAnimation>
            <MenuBar ChangeStep={this.ChangeStep} step={step} />
            <Faqs />
            <Footer />
          </>
        );

      // never forget the default case, otherwise VS code would be mad!
      default:
      // do nothing
    }
    return (
      <div className="">
        <Scrolltop />
        <Header />
        <StickyHeader />
        <ScrollAnimation
          delay={500}
          animateIn="animate__fadeInLeft"
          animateOnce={true}
        >
          <MainBanner />
        </ScrollAnimation>
        <MenuBar />
        <Services />
        <OurStory />
        <WhyUs />
        <Journey />
        <Techtext text="Contact Us Now" />
        <CoreValues />
        <Whatwedo />
        <OurHistory />
        <Technologies />
            <Joining />
            <JoiningProccess />
            <JoinTeam />
            <ApplyVanacy />
            <OurPeople />
            <Faqs />
        <Footer />
      </div>
    );
  }
}

export default About;
