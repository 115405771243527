import React from 'react'
import banner_icon from '../../../assets/expertise/expertisebanner.png'
function Mainbanner(props) {
  return (
    <div>
      <div class='img-container my-0'>
        <img
          src={banner_icon}
          class='card-img-top no-radius expertise-banner'
          alt='Snow'
        />
        <div className='about-center text-white text-left'>
          <div className='p7 font-weight-light'>EXPERTISE</div>
          <div class='p9 p-9 font-weight-bold mx-auto mt-3'>
            WHAT IS YOUR
            <span className='bb-lgreen ml-2'>CHALLENGE?</span>
          </div>
          <div class='font-weight-light mt-4 helvetica mx-auto'>
            From startup to enterprise, the full range of engineering services
            your <br /> company needs
          </div>
          <a href='javascript:void(0)'>
            <div class='font-weight-bold mt-4 w-fit p21 mr-auto b-n-link'>
              CONTACT US TODAY <i className='ml-2 fa fa-arrow-right'></i>
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Mainbanner
