import React from "react";
import Footer from "../shared/Footer";
import Header from "../shared/Header";
import Scrolltop from "../shared/Scrolltop";
import StickyHeader from "../shared/StickyHeader";
import Techtext from "../shared/Techtext";
import Faqs from "../small/aboutsections/Faqs";
import Journey from "../small/aboutsections/Journey";
import Coreservice from "../small/Expertisesections/Coreservice";
import Globalbrand from "../small/Expertisesections/Globalbrand";
import Lifecycle from "../small/Expertisesections/Lifecycle";
import MainBanner from "../small/Expertisesections/Mainbanner";
import WhyUs from "../small/homesection/WhyUs";
function Expertise(props) {
  return (
    <div>
      <Scrolltop />
      <Header />
      <StickyHeader />
      <MainBanner />
      <Coreservice />
      <Lifecycle />
      <Globalbrand />
      <WhyUs />
      <Journey />
      <Techtext text="Contact Us Now" />
      <Faqs />
      <Footer />
    </div>
  );
}

export default Expertise;
