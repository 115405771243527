import React from 'react'
import story_icon from '../../../assets/Group 3947.png'
function OurStory(props) {
  return (
    <div>
      <div class='bg-story mb-1'>
        <div className='col-lg-10 mx-auto'>
          <div className='text-white row mx-auto py--80 col-lg-12'>
            <div class='p9 font-weight-bold mx-auto mt-xl-4 col-12'>
              OUR STORY
            </div>
            <div class='font-weight-light linee-h-2 text-md-left text-justify helvetica op-9 lh-13 mt-3 p25 p--15'>
              Its very simple… Allomate is built by a small team of passionate
              entrepreneurs, seasoned professionals, software engineers, and
              designers. We are a bunch of idea junkies always excited about
              building customized digital solutions & platforms. We are geared
              towards businesses who want to run a digital Initiative.What sets
              us apart is the combined professional experience of 50+ years of
              our entire team in the Telecom, Retail, Financial services and
              Microfinance Institutions. This professional experience has
              enabled the our team to establish a first time right approach
              towards our clients needs. .What sets us apart is the combined
              professional experience of 50+ years of our entire team in the
              Telecom, Retail, Financial services and Microfinance Institutions.
              This professional experience has enabled the our team to establish
              a first time right approach towards our clients needs..What sets
              us apart is the combined professional experience of 50+ years of
              our entire team in the Telecom, Retail, Financial services and
              Microfinance Institutions. This professional experience has
              enabled the our team to establish a first time right approach
              towards our clients needs.
            </div>
            <div class='mb-xl-3 font-weight-light linee-h-2 text-md-left text-justify helvetica op-9 lh-13 mt-3 p25 p--15'>
              Allomate is built by a small team of passionate entrepreneurs,
              seasoned professionals, software engineers, and designers. We are
              a bunch of idea junkies always excited about building customized
              digital solutions & platforms. We are geared towards businesses
              who want to run a digital Initiative.What sets us apart is the
              combined professional experience of 50+ years of our entire team
              in the Telecom, Retail, Financial services and Microfinance
              Institutions. This professional experience has enabled the our
              team to establish a first time right approach towards our clients
              needs. .What sets us apart is the combined professional experience
              of 50+ years of our entire team in the Telecom, Retail, Financial
              services and Microfinance Institutions. This professional
              experience has enabled the our team to establish a first time
              right approach towards our clients needs..What sets us apart is
              the combined professional experience of 50+ years of our entire
              team in the Telecom, Retail, Financial services and Microfinance
              Institutions. This professional experience has enabled the our
              team to establish a first time right approach towards our clients
              needs.What sets us apart is the combined.
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurStory
