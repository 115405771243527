import React from 'react'
import img from '../../../assets/group 47.jpg'
import { Link } from 'react-router-dom'
function Studyban(props) {
  return (
    <div>
      <div class='webbg my-0'>
        <div className='col-lg-10 mx-auto'>
          <div className='row'>
            <div className='col-lg-7 col-md-7'>
              <div className='text-white text-left'>
                <div className='p3 font-weight-light mb-3 mb-xl-5'>
                  Home
                  <span className='font-weight'> / CaseStudies</span>
                  <span className='font-weight-bold'>
                    / Support Center for Semiconductor Provider
                  </span>
                </div>
                <div className='p8 font-weight-light'>CASE STUDIES</div>
                <div class='p9 font-weight-bold mx-auto'>
                  SUPPORT CENTER FOR SEMI
                  <span className='bb-lgreen'>CONDUCTOR</span> PROVIDER
                </div>
                <div class='font-weight-light mt-3 mt-xl-5 p3 helvetica mx-auto'>
                  <p>
                    Here are some examples of the excellent products that we’ve
                    helped to send out into the world.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-lg-5 col-md-5'></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Studyban
