import React from 'react'
import Marquee from 'react-fast-marquee'
import img from '../../../assets/Group.svg'
function ClientsMarquee(props) {
  const clients = [{ path: '../../../assets/Group.svg' }]
  return (
    <div className='row mx-auto mt-5'>
      <div className='col-xl-12 mar-grad col-lg-12 col-md-11 col-11 mx-auto pt-3 pt-xl-4 pt-lg-5 mt-xl-2'>
        <Marquee>
          <div className='d-flex align-items-center'>
            {Array.from({ length: 20 }, (_, i) => (
              <img src={img} height='40' className='mx-4' />
            ))}
          </div>
        </Marquee>
      </div>
    </div>
  )
}

export default ClientsMarquee
