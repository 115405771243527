import React, { useState } from 'react'
import Footer from '../../../shared/Footer'
import Header from '../../../shared/Header'
import Scrolltop from '../../../shared/Scrolltop'
import StickyHeader from '../../../shared/StickyHeader'
import Faqs from '../../../small/aboutsections/Faqs'

import idea from '../../../../assets/expertise/Services Details Page/Idea.svg'
import scale from '../../../../assets/expertise/Services Details Page/Scale.svg'
import security from '../../../../assets/expertise/Services Details Page/Security.svg'
import user from '../../../../assets/expertise/Services Details Page/User.svg'
import php_img from '../../../../assets/expertise/Services Details Page/Rectangle 897/Rectangle 897.png'
import fulstack from '../../../../assets/expertise/Services Details Page/Sertab/noun_Full stack_2230389.svg'
import device from '../../../../assets/expertise/Services Details Page/Sertab/noun_devices_3322827.svg'
import api from '../../../../assets/expertise/Services Details Page/Sertab/noun_API_719154.svg'
import scal from '../../../../assets/expertise/Services Details Page/Sertab/noun_Scale_3378276.svg'
import mockup from '../../../../assets/expertise/Services Details Page/Mockup.png'
import Overview from './Overview'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import WebCarousel from './WebCarousel'
function Webdevelopment(props) {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  }
  const [service, setService] = useState('php')
  return (
    <div>
      <Scrolltop />
      <Header />
      <StickyHeader />
      <div>
        <div class='webbg my-0'>
          <div className='col-lg-10 mx-auto'>
            <div className='row'>
              <div className='col-lg-6 col-md-7'>
                <div className='text-white text-left'>
                  <p className='p3 font-weight-light mb-3 mb-xl-5'>
                    Home / Services
                    <span className='font-weight-bold'>/ Web Development</span>
                  </p>
                  <p className='p8 font-weight-light'>WEB DEVELOPMENT</p>
                  <h1 class='p9 font-weight-bold mx-auto mb-0'>
                    FULL-SERVICE WEB DESIGN AND{' '}
                    <span className='bb-lgreen ml-2'> DEVELOPMENT</span>
                  </h1>
                  <p class='font-weight-light lh-12 mt-3 mt-xl-5 p3 helvetica mx-auto'>
                    We help you build secure, stable, and scalable web
                    applications, portals and solutions that your customers will
                    love.
                  </p>
                </div>
              </div>
              <div className='col-lg-6 col-md-5'></div>
            </div>
          </div>
        </div>
      </div>
      <Overview />
      <div className='bg-light-v pb--80'>
        <div className='row mx-auto'>
          <div className='col-xl-10 mx-auto text-center'>
            <h1 className='p9 darker font-weight-bold'>
              OUR WEB DEVELOPMENT<br></br> SERVICES
            </h1>
          </div>
          <div className='col-xl-10 mx-auto'>
            <div className='row mx-auto text-left pt-xl-4 pt-2'>
              <div className='col-lg-3 col-md-3 px-0 py-4 order-md-3'>
                <div className='row align-items-center h-75'>
                  <div className='col-12 col-md-12'>
                    <button
                      className={`p6 ${
                        service === 'php'
                          ? 'font-clr-green font-weight-bold border-0 bg-transparent'
                          : 'darker font-weight-lighter border-0 bg-transparent'
                      } pointer`}
                      onClick={() => {
                        setService('php')
                      }}
                    >
                      PHP Development
                    </button>
                  </div>
                  <div className='col-12 col-md-12'>
                    <button
                      className={`p6 ${
                        service === 'node'
                          ? 'font-clr-green font-weight-bold border-0 bg-transparent'
                          : 'darker font-weight-lighter border-0 bg-transparent'
                      } pointer`}
                      onClick={() => {
                        setService('node')
                      }}
                    >
                      Node Js Development
                    </button>
                  </div>
                  <div className='col-12 col-md-12'>
                    <button
                      className={`p6 ${
                        service === 'angular'
                          ? 'font-clr-green font-weight-bold border-0 bg-transparent'
                          : 'darker font-weight-lighter border-0 bg-transparent'
                      } pointer`}
                      onClick={() => {
                        setService('angular')
                      }}
                    >
                      Angular Js
                    </button>
                  </div>
                  <div className='col-12 col-md-12'>
                    <button
                      className={`p6 ${
                        service === 'ui'
                          ? 'font-clr-green font-weight-bold border-0 bg-transparent'
                          : 'darker font-weight-lighter border-0 bg-transparent'
                      } pointer`}
                      onClick={() => {
                        setService('ui')
                      }}
                    >
                      UX / UI Design
                    </button>
                  </div>
                  <div className='col-12 col-md-12'>
                    <button
                      className={`p6 ${
                        service === 'html'
                          ? 'font-clr-green font-weight-bold border-0 bg-transparent'
                          : 'darker font-weight-lighter border-0 bg-transparent'
                      } pointer`}
                      onClick={() => {
                        setService('html')
                      }}
                    >
                      HTML
                    </button>
                  </div>
                </div>
              </div>
              <div className='col-lg-5 col-md-5 order-md-1'>
                <h2 className='p3 darker mb-xl-3 mb-2 font-weight-bold p7'>
                  {service === 'php'
                    ? `PHP DEVELOPMENT`
                    : service === 'node'
                    ? `Node JS DEVELOPMENT`
                    : service === 'angular'
                    ? `Angular Js DEVELOPMENT`
                    : service === 'ui'
                    ? `UI / UX DEVELOPMENT`
                    : `HTML DEVELOPMENT`}
                </h2>
                <p className='p15 darker font-weight-lighter'>
                  {service === 'php'
                    ? `Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                sea takimata sanctus est Lorem ipsum dolor sit amet.`
                    : service === 'node'
                    ? `node Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                sea takimata sanctus est Lorem ipsum dolor sit amet.`
                    : service === 'angular'
                    ? `angular Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                sea takimata sanctus est Lorem ipsum dolor sit amet.`
                    : service === 'ui'
                    ? `ui Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                sea takimata sanctus est Lorem ipsum dolor sit amet.`
                    : `html Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem
                ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
                nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no
                sea takimata sanctus est Lorem ipsum dolor sit amet.`}
                </p>
              </div>
              <div className='col-lg-4 col-md-4 pr-0 order-md-2'>
                <img src={php_img} className='w-100 br-8' alt='' />
              </div>
            </div>
            <div className='col-12 mt-3 text-center'>
              <button class='border-0 profile-btn zoom-in py-2 px-5 btn-small mx-auto bg-blue'>
                <span class='text-white d-block px-xl-2 py-1'>
                  Start My Project
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='bg-white py--80'>
        <div className='row mx-auto'>
          <div className='col-xl-10 mx-auto text-center'>
            <h1 className='p9 darker font-weight-bold w-75 m-auto'>
              Achieve your goals faster with the right web development services
            </h1>
            {/*<h1 className="p9 darker font-weight-bold">
              DEVELOPMENT SERVICES
            </h1>*/}
            <p className='pb-xl-5 py-2 p3 para-d font-weight-lighter'>
              Leverage tailor-made solutions to drive a wide range of benefits.
            </p>
          </div>
          <div className='col-xl-10 mx-auto'>
            <div className='row mx-auto'>
              <div className='col-lg-3 col-md-6 text-center darker my-4 px-4'>
                <img height='45' src={idea} className='mx-auto' alt='' />
                <h3 className='p10 py-xxl-3 lh-2'>
                  TRANSFORM YOUR IDEA INTO A PRODUCT
                </h3>
                <p className='font-weight-light p3 lh-2'>
                  Create and launch a successful MVP, and future-proof your
                  product using the right web technology tools.
                </p>
              </div>
              <div className='col-lg-3 col-md-6 text-center darker my-4 px-4'>
                <img height='45' src={user} className='mx-auto' alt='' />
                <h3 className='p10 py-xxl-3 lh-2'>
                  ATTRACT AND RETAIN NEW CUSTOMERS
                </h3>
                <p className='font-weight-light p3 lh-2'>
                  Cast a wider net to capture your target audience with an
                  innovative, functional, reliable and truly helpful web app.
                </p>
              </div>
              <div className='col-lg-3 col-md-6 text-center darker my-4 px-4'>
                <img height='45' src={security} className='mx-auto' alt='' />
                <h3 className='p10 py-xxl-3 lh-2'>
                  PRIORITIZE APP SECURITY & STABILITY
                </h3>
                <p className='font-weight-light p3 lh-2'>
                  Create and launch a successful MVP, and future-proof your
                  product using the right web technology tools.
                </p>
              </div>
              <div className='col-lg-3 col-md-6 text-center darker my-4 px-4'>
                <img height='45' src={scale} className='mx-auto' alt='' />
                <h3 className='p10 py-xxl-3 lh-2'>
                  SCALE YOUR WAY INTO THE DIGITAL MARKET
                </h3>
                <p className='font-weight-light p3 lh-2'>
                  Enable adding features & functionalities to your web solutions
                  that customize app experience for users.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='bg-light-v py--80'>
        <div className='row mx-auto'>
          <div className='col-xl-10 mx-auto text-center'>
            <h1 className='p9 darker font-weight-bold'>
              Our Web Development
              <br></br>
              Service Features
            </h1>
            <p class='para-d mt-2 mb-2 p8 p-14'>
              Reasons why our clients return to us time and again
            </p>
            <div className='row mx-auto text-right pt-xl-4 pt-2'>
              <div className='col-lg-6 col-md-6'>
                <div className='row mb-xl-4 mb-2'>
                  <div className='col-12 col-md-2 order-md-2'>
                    <div className=' bg-g-hov rounded-circle b-green w-fit ml-auto'>
                      <img
                        src={api}
                        className='m-auto p-3'
                        height='80'
                        width='80'
                        alt=''
                      />
                    </div>
                  </div>
                  <div className='col-12 col-md-10 order-md-1'>
                    <h6 className='p6 darker p-2'>FULL STACK EXPERTISE</h6>
                    <p className='p3 text-left font-weight-lighter darker p-3'>
                      We are one of the very few who have expertise in all of
                      the latest technologies on all major platforms.
                    </p>
                  </div>
                </div>
                <div className='row mb-xl-4 mb-2'>
                  <div className='col-12 col-md-2 order-md-2'>
                    <div className=' bg-g-hov rounded-circle b-green w-fit ml-auto'>
                      <img
                        src={device}
                        className='m-auto p-3'
                        height='80'
                        width='80'
                        alt=''
                      />
                    </div>
                  </div>
                  <div className='col-12 col-md-10 order-md-1'>
                    <h6 className='p6 darker p-2'>DEVICE-AGNOSTIC SOLUTIONS</h6>
                    <p className='p3 text-left font-weight-lighter darker p-3'>
                      All of our solutions are mobile-ready and are tested on
                      all major mobile devices.
                    </p>
                  </div>
                </div>

                <div className='row mb-xl-4 mb-2'>
                  <div className='col-12 col-md-2 order-md-2'>
                    <div className=' bg-g-hov rounded-circle b-green w-fit ml-auto'>
                      <img
                        src={api}
                        className='m-auto p-3'
                        height='80'
                        width='80'
                        alt=''
                      />
                    </div>
                  </div>
                  <div className='col-12 col-md-10 order-md-1'>
                    <h6 className='p6 darker p-2'>
                      INTEGRATION AND API EXPERTS
                    </h6>
                    <p className='p3 text-left font-weight-lighter darker p-3'>
                      We have a penchant of carrying out even the most difficult
                      software integration.
                    </p>
                  </div>
                </div>

                <div className='row mb-xl-4 mb-2'>
                  <div className='col-12 col-md-2 order-md-2'>
                    <div className=' bg-g-hov rounded-circle b-green w-fit ml-auto'>
                      <img
                        src={scal}
                        className='m-auto p-3'
                        height='80'
                        width='80'
                        alt=''
                      />
                    </div>
                  </div>
                  <div className='col-12 col-md-10 order-md-1'>
                    <h6 className='p6 darker p-2'>
                      SCALABILITY AND FLEXIBILITY
                    </h6>
                    <p className='p3 text-left font-weight-lighter darker p-3'>
                      Using micro-service architecture, we create solutions that
                      are easily extensible and scalable.
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-lg-6 col-md-6'>
                <img src={mockup} className='mx-auto w-100' alt='' />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class='bg-ex-carousel my-0'>
        <div class='text-white row mx-auto py--80'>
          <div className='row mx-auto text-left'>
            <div class='p9 font-weight-bold mx-auto col-8'>FEATURED WORK</div>
            <div class='col-xl-8 mx-auto col-11 font-weight-light linee-h-2 text-justify helvetica op-9 lh-13 mt-3 p25 p--15 mx-auto'>
              We Provide IT solutions and business-transforming digital
              solutions to our clients. Get the attractive web design and mobile
              apps developed.
            </div>
            <div className='col-10 ml-auto pr-0'>{/* <WebCarousel /> */}</div>
          </div>
        </div>
      </div>
      <div className='bg-white py--80'>
        <div className='row mx-auto'>
          <div className='col-xl-10 mx-auto text-center'>
            <h1 className='p9 darker font-weight-bold'>
              Tailored web applications that<br></br> solve your business
              challenges
            </h1>

            <p className='pb-xl-5 px-xl-5 mx-xl-5 py-2 p3 para-d font-weight-lighter'>
              Your web development partner should help you achieve your unique
              business goals and solve real-world challenges. That’s why we
              offer a range of services that help you build a successful product
              from scratch or improve an existing one, applying technologies
              that best fit the market’s and user’s needs.
            </p>
          </div>
          <div className='col-xl-11 mx-auto'>
            <div className='row mx-auto'>
              <div className='col-lg-3 col-md-4 text-left darker mb-5'>
                <div className='border br-20 p-xl-4 py-xxl-5 h-100  w-95 my-auto p-3'>
                  <h3 className='p10  lh-13 mb-2'>CUSTOM APPLICATIONS</h3>
                  <p className='font-weight-light p3 lh-2'>
                    Build a custom web app from scratch, migrate a legacy
                    backend, or streamline existing front-end functionality
                  </p>
                </div>
              </div>
              <div className='col-lg-3 col-md-4 text-left darker mb-5'>
                <div className='border br-20 p-xl-4 py-xxl-5 h-100  w-95 my-auto p-3'>
                  <h3 className='p10  lh-13 mb-2'>WEB PORTALS</h3>
                  <p className='font-weight-light p3 lh-2'>
                    Create web portals that offer collaboration, access to
                    aggregated information and self-service workflows
                  </p>
                </div>
              </div>
              <div className='col-lg-3 col-md-4 text-left darker mb-5'>
                <div className='border br-20 p-xl-4 py-xxl-5 h-100  w-95 my-auto p-3'>
                  <h3 className='p10  lh-13 mb-2'>E-COMMERCE</h3>
                  <p className='font-weight-light p3 lh-2'>
                    Craft end-to-end e-commerce solutions with payment gateways,
                    high-end modules & flawless UX
                  </p>
                </div>
              </div>
              <div className='col-lg-3 col-md-4 text-left darker mb-5'>
                <div className='border br-20 p-xl-4 py-xxl-5 h-100  w-95 my-auto p-3'>
                  <h3 className='p10  lh-13 mb-2'>WEBSITES</h3>
                  <p className='font-weight-light p3 lh-2'>
                    Develop websites with easy navigation, robust architecture,
                    visual cues for users, and action-oriented design
                  </p>
                </div>
              </div>
              <div className='col-lg-3 col-md-4 text-left darker mb-5'>
                <div className='border br-20 p-xl-4 py-xxl-5 h-100  w-95 my-auto p-3'>
                  <h3 className='p10  lh-13 mb-2'>OPEN-SOURCE WEBSITES</h3>
                  <p className='font-weight-light p3 lh-2'>
                    Revamp or develop your website with editable source code for
                    quicker roll-out times in limited budget
                  </p>
                </div>
              </div>
              <div className='col-lg-3 col-md-4 text-left darker mb-5'>
                <div className='border br-20 p-xl-4 py-xxl-5 h-100  w-95 my-auto p-3'>
                  <h3 className='p10  lh-13 mb-2'>SAAS PRODUCTS</h3>
                  <p className='font-weight-light p3 lh-2'>
                    Reduce launch time & innovate at scale with SaaS
                    accelerators, reusable frameworks and components
                  </p>
                </div>
              </div>
              <div className='col-lg-3 col-md-4 text-left darker mb-5'>
                <div className='border br-20 p-xl-4 py-xxl-5 h-100  w-95 my-auto p-3'>
                  <h3 className='p10  lh-13 mb-2'>API DEVELOPMENT</h3>
                  <p className='font-weight-light p3 lh-2'>
                    Consume or build APIs that suit your product needs,
                    including voice, video, payment, cloud, accounting, etc.
                  </p>
                </div>
              </div>
              <div className='col-lg-3 col-md-4 text-left darker mb-5'>
                <div className='border br-20 p-xl-4 py-xxl-5 h-100  w-95 my-auto p-3'>
                  <h3 className='p10  lh-13 mb-2'>OPTIMIZATION & SUPPORT</h3>
                  <p className='font-weight-light p3 lh-2'>
                    Rescue & prevent your website from performance issues with
                    regular bug fixes, improvements & upgrades
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Faqs />
      <Footer />
    </div>
  )
}

export default Webdevelopment
