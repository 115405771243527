import React from 'react'
import Slider from 'infinite-react-carousel'
import Carousel from 'react-bootstrap/Carousel'
import '../../styles/carousel.css'
function ServicesCarousel(props) {
  return (
    <Carousel className='inner-h'>
      <Carousel.Item className=''>
        <div className=''>
          <div className='col-lg-10 mx-auto'>
            <div className='row'>
              <div className='col-lg-4 col-md'>
                <div className='card-carousel service-card-h text-center mt-5 p-4 pt-5 pb-5 '>
                  <h4 className='p10 mt-xxl-5 font-weight-bold'>
                    WEB DEVELOPMENT
                  </h4>
                  <p className='my-xxl-3 align-items-center lh-2 card-d-para'>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua.
                  </p>
                  <div class='mb-4 pt-3 overlay-box mb-0 row mx-auto text-center'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='99.999'
                      height='64.757'
                      viewBox='0 0 99.999 64.757'
                    >
                      <g
                        id='noun_Laptop_18867'
                        transform='translate(0 -17.622)'
                      >
                        <g id='Group_66' data-name='Group 66'>
                          <path
                            id='Path_39'
                            data-name='Path 39'
                            d='M83.817,23.708H16.183A3.144,3.144,0,0,0,13.04,26.85V67.071a3.144,3.144,0,0,0,3.143,3.144H83.818a3.149,3.149,0,0,0,3.143-3.144V26.85A3.15,3.15,0,0,0,83.817,23.708Zm1.149,43.363a1.148,1.148,0,0,1-1.148,1.15H16.183a1.149,1.149,0,0,1-1.148-1.15V26.85A1.149,1.149,0,0,1,16.183,25.7H83.818a1.148,1.148,0,0,1,1.148,1.148Z'
                            fill='#1d4354'
                          />
                          <path
                            id='Path_40'
                            data-name='Path 40'
                            d='M99,74.306H92.967a3.025,3.025,0,0,0,.223-1.147V20.765a3.149,3.149,0,0,0-3.143-3.143H9.954A3.15,3.15,0,0,0,6.81,20.765V73.159a3,3,0,0,0,.225,1.147H1a.992.992,0,0,0-1,1c0,4.036,6.42,7.076,14.947,7.076H85.054c8.525,0,14.945-3.04,14.945-7.076A.99.99,0,0,0,99,74.306ZM85.055,80.385H14.947c-6.588,0-11.454-1.994-12.667-4.085H40.493a2.135,2.135,0,0,0,1.9,1.141H57.609a2.133,2.133,0,0,0,1.9-1.141H97.719C96.508,78.391,91.643,80.385,85.055,80.385ZM8.805,73.158V20.765a1.149,1.149,0,0,1,1.149-1.148H90.047A1.149,1.149,0,0,1,91.2,20.765V73.159a1.147,1.147,0,0,1-1.148,1.147H9.954A1.148,1.148,0,0,1,8.805,73.158Z'
                            fill='#1d4354'
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                  <button className='card-d-btn p-0'>
                    <div className='row px-2'>
                      <p className='h6 pt-2 m-0'>STAY UP AND RUNNING</p>
                      <i class='fas fa-arrow-right v-align mx-2 py-2'></i>
                    </div>
                  </button>
                </div>
              </div>
              <div className='d-none d-md-block d-lg-block col-lg-4 col-md'>
                <div className=' card-carousel service-card-h text-center mt-5 p-4 pt-5 pb-5 '>
                  <h4 className='p10 mt-xxl-5 font-weight-bold'>
                    Mobile App Development
                  </h4>
                  <p className='my-xxl-3 align-items-center lh-2 card-d-para'>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua.
                  </p>
                  <div className='pt-3 overlay-box row mx-auto text-center mb-4'>
                    <svg
                      id='noun_devices_931974'
                      xmlns='http://www.w3.org/2000/svg'
                      width='86.672'
                      height='86.672'
                      viewBox='0 0 86.672 86.672'
                    >
                      <g id='Layer_2' data-name='Layer 2'>
                        <g id='Group_67' data-name='Group 67'>
                          <path
                            id='Path_41'
                            data-name='Path 41'
                            d='M41.987,83.991H4.053A1.363,1.363,0,0,1,2.69,82.619V4.053A1.363,1.363,0,0,1,4.053,2.69H60.967a1.363,1.363,0,0,1,1.345,1.363V17.6a1.345,1.345,0,0,0,2.69,0V4.053A4.061,4.061,0,0,0,60.967,0H4.053A4.061,4.061,0,0,0,0,4.053V82.619a4.061,4.061,0,0,0,4.053,4.053H41.987a1.345,1.345,0,1,0,0-2.69Z'
                            fill='#1d4354'
                          />
                          <path
                            id='Path_42'
                            data-name='Path 42'
                            d='M88.245,27.2h-29.8a4.061,4.061,0,0,0-4.053,4.053v54.18a4.061,4.061,0,0,0,4.053,4.053h29.8A4.061,4.061,0,0,0,92.3,85.433V31.253A4.061,4.061,0,0,0,88.245,27.2Zm1.363,58.277a1.363,1.363,0,0,1-1.363,1.363h-29.8a1.363,1.363,0,0,1-1.363-1.363V31.253a1.363,1.363,0,0,1,1.363-1.363h29.8a1.363,1.363,0,0,1,1.363,1.363Z'
                            transform='translate(-5.625 -2.813)'
                            fill='#1d4354'
                          />
                          <circle
                            id='Ellipse_4'
                            data-name='Ellipse 4'
                            cx='2.708'
                            cy='2.708'
                            r='2.708'
                            transform='translate(29.793 73.143)'
                            fill='#1d4354'
                          />
                          <circle
                            id='Ellipse_5'
                            data-name='Ellipse 5'
                            cx='2.708'
                            cy='2.708'
                            r='2.708'
                            transform='translate(65.011 73.143)'
                            fill='#1d4354'
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                  <button className='card-d-btn p-0'>
                    <div className='row px-2'>
                      <p className='h6 pt-2 m-0'>STAY UP AND RUNNING</p>
                      <i class='fas fa-arrow-right v-align mx-2 py-2'></i>
                    </div>
                  </button>
                </div>
              </div>
              <div className='d-none d-lg-block col-lg-4 col-md'>
                <div className=' card-carousel service-card-h text-center mt-5 p-4 pt-5 pb-5 '>
                  <h4 className='p10 mt-xxl-5 font-weight-bold'>
                    Cloud Services & DevOps
                  </h4>
                  <p className='my-xxl-3 align-items-center lh-2 card-d-para'>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua.
                  </p>
                  <div className='pt-3 overlay-box row mx-auto text-center mb-4'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='83.69'
                      height='62.446'
                      viewBox='0 0 83.69 62.446'
                    >
                      <g id='noun_Cloud_1820804' transform='translate(-11 -21)'>
                        <path
                          id='Path_81'
                          data-name='Path 81'
                          d='M76.236,83.446H29.455a18.474,18.474,0,0,1-1.18-36.91v-.966a24.571,24.571,0,0,1,49.141,0v.966a18.474,18.474,0,0,1-1.18,36.91Zm-46.781-33.8a15.236,15.236,0,0,0,0,30.472H76.128a15.236,15.236,0,0,0,0-30.472H73.875l.215-1.824a18.186,18.186,0,0,0,.107-2.36,21.352,21.352,0,0,0-42.7,0,18.187,18.187,0,0,0,.107,2.36l.215,1.824h-2.36Z'
                          transform='translate(0)'
                          fill='#1d4354'
                        />
                      </g>
                    </svg>
                  </div>
                  <button className='card-d-btn p-0'>
                    <div className='row px-2'>
                      <p className='h6 pt-2 m-0'>STAY UP AND RUNNING</p>
                      <i class='fas fa-arrow-right v-align mx-2 py-2'></i>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item className=''>
        <div className=''>
          <div className='col-lg-10 mx-auto'>
            <div className='row'>
              <div className='col-lg-4 col-md'>
                <div className='card-carousel service-card-h text-center mt-5 p-4 pt-5 pb-5 '>
                  <h4 className='p10 mt-xxl-5 font-weight-bold'>
                    WEB DEVELOPMENT
                  </h4>
                  <p className='my-xxl-3 align-items-center lh-2 card-d-para'>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua.
                  </p>
                  <div class='mb-4 pt-3 overlay-box mb-0 row mx-auto text-center'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='99.999'
                      height='64.757'
                      viewBox='0 0 99.999 64.757'
                    >
                      <g
                        id='noun_Laptop_18867'
                        transform='translate(0 -17.622)'
                      >
                        <g id='Group_66' data-name='Group 66'>
                          <path
                            id='Path_39'
                            data-name='Path 39'
                            d='M83.817,23.708H16.183A3.144,3.144,0,0,0,13.04,26.85V67.071a3.144,3.144,0,0,0,3.143,3.144H83.818a3.149,3.149,0,0,0,3.143-3.144V26.85A3.15,3.15,0,0,0,83.817,23.708Zm1.149,43.363a1.148,1.148,0,0,1-1.148,1.15H16.183a1.149,1.149,0,0,1-1.148-1.15V26.85A1.149,1.149,0,0,1,16.183,25.7H83.818a1.148,1.148,0,0,1,1.148,1.148Z'
                            fill='#1d4354'
                          />
                          <path
                            id='Path_40'
                            data-name='Path 40'
                            d='M99,74.306H92.967a3.025,3.025,0,0,0,.223-1.147V20.765a3.149,3.149,0,0,0-3.143-3.143H9.954A3.15,3.15,0,0,0,6.81,20.765V73.159a3,3,0,0,0,.225,1.147H1a.992.992,0,0,0-1,1c0,4.036,6.42,7.076,14.947,7.076H85.054c8.525,0,14.945-3.04,14.945-7.076A.99.99,0,0,0,99,74.306ZM85.055,80.385H14.947c-6.588,0-11.454-1.994-12.667-4.085H40.493a2.135,2.135,0,0,0,1.9,1.141H57.609a2.133,2.133,0,0,0,1.9-1.141H97.719C96.508,78.391,91.643,80.385,85.055,80.385ZM8.805,73.158V20.765a1.149,1.149,0,0,1,1.149-1.148H90.047A1.149,1.149,0,0,1,91.2,20.765V73.159a1.147,1.147,0,0,1-1.148,1.147H9.954A1.148,1.148,0,0,1,8.805,73.158Z'
                            fill='#1d4354'
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                  <button className='card-d-btn p-0'>
                    <div className='row px-2'>
                      <p className='h6 pt-2 m-0'>STAY UP AND RUNNING</p>
                      <i class='fas fa-arrow-right v-align mx-2 py-2'></i>
                    </div>
                  </button>
                </div>
              </div>
              <div className='d-none d-md-block d-lg-block col-lg-4 col-md'>
                <div className=' card-carousel service-card-h text-center mt-5 p-4 pt-5 pb-5 '>
                  <h4 className='p10 mt-xxl-5 font-weight-bold'>
                    Mobile App Development
                  </h4>
                  <p className='my-xxl-3 align-items-center lh-2 card-d-para'>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua.
                  </p>
                  <div className='pt-3 overlay-box row mx-auto text-center mb-4'>
                    <svg
                      id='noun_devices_931974'
                      xmlns='http://www.w3.org/2000/svg'
                      width='86.672'
                      height='86.672'
                      viewBox='0 0 86.672 86.672'
                    >
                      <g id='Layer_2' data-name='Layer 2'>
                        <g id='Group_67' data-name='Group 67'>
                          <path
                            id='Path_41'
                            data-name='Path 41'
                            d='M41.987,83.991H4.053A1.363,1.363,0,0,1,2.69,82.619V4.053A1.363,1.363,0,0,1,4.053,2.69H60.967a1.363,1.363,0,0,1,1.345,1.363V17.6a1.345,1.345,0,0,0,2.69,0V4.053A4.061,4.061,0,0,0,60.967,0H4.053A4.061,4.061,0,0,0,0,4.053V82.619a4.061,4.061,0,0,0,4.053,4.053H41.987a1.345,1.345,0,1,0,0-2.69Z'
                            fill='#1d4354'
                          />
                          <path
                            id='Path_42'
                            data-name='Path 42'
                            d='M88.245,27.2h-29.8a4.061,4.061,0,0,0-4.053,4.053v54.18a4.061,4.061,0,0,0,4.053,4.053h29.8A4.061,4.061,0,0,0,92.3,85.433V31.253A4.061,4.061,0,0,0,88.245,27.2Zm1.363,58.277a1.363,1.363,0,0,1-1.363,1.363h-29.8a1.363,1.363,0,0,1-1.363-1.363V31.253a1.363,1.363,0,0,1,1.363-1.363h29.8a1.363,1.363,0,0,1,1.363,1.363Z'
                            transform='translate(-5.625 -2.813)'
                            fill='#1d4354'
                          />
                          <circle
                            id='Ellipse_4'
                            data-name='Ellipse 4'
                            cx='2.708'
                            cy='2.708'
                            r='2.708'
                            transform='translate(29.793 73.143)'
                            fill='#1d4354'
                          />
                          <circle
                            id='Ellipse_5'
                            data-name='Ellipse 5'
                            cx='2.708'
                            cy='2.708'
                            r='2.708'
                            transform='translate(65.011 73.143)'
                            fill='#1d4354'
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                  <button className='card-d-btn p-0'>
                    <div className='row px-2'>
                      <p className='h6 pt-2 m-0'>STAY UP AND RUNNING</p>
                      <i class='fas fa-arrow-right v-align mx-2 py-2'></i>
                    </div>
                  </button>
                </div>
              </div>
              <div className='d-none d-lg-block col-lg-4 col-md'>
                <div className=' card-carousel service-card-h text-center mt-5 p-4 pt-5 pb-5 '>
                  <h4 className='p10 mt-xxl-5 font-weight-bold'>
                    Cloud Services & DevOps
                  </h4>
                  <p className='my-xxl-3 align-items-center lh-2 card-d-para'>
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore magna
                    aliquyam erat, sed diam voluptua.
                  </p>
                  <div className='pt-3 overlay-box row mx-auto text-center mb-4'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='83.69'
                      height='62.446'
                      viewBox='0 0 83.69 62.446'
                    >
                      <g id='noun_Cloud_1820804' transform='translate(-11 -21)'>
                        <path
                          id='Path_81'
                          data-name='Path 81'
                          d='M76.236,83.446H29.455a18.474,18.474,0,0,1-1.18-36.91v-.966a24.571,24.571,0,0,1,49.141,0v.966a18.474,18.474,0,0,1-1.18,36.91Zm-46.781-33.8a15.236,15.236,0,0,0,0,30.472H76.128a15.236,15.236,0,0,0,0-30.472H73.875l.215-1.824a18.186,18.186,0,0,0,.107-2.36,21.352,21.352,0,0,0-42.7,0,18.187,18.187,0,0,0,.107,2.36l.215,1.824h-2.36Z'
                          transform='translate(0)'
                          fill='#1d4354'
                        />
                      </g>
                    </svg>
                  </div>
                  <button className='card-d-btn p-0'>
                    <div className='row px-2'>
                      <p className='h6 pt-2 m-0'>STAY UP AND RUNNING</p>
                      <i class='fas fa-arrow-right v-align mx-2 py-2'></i>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Carousel.Item>
    </Carousel>
  )
}

export default ServicesCarousel
