const data = [
  {
    main: "We're DigiMark Developers",
    sub: "SECURITY",
  },
  {
    main: "",
    sub: "Managed",
  },
  {
    main: "",
    sub: "Support",
  },
  {
    main: "",
    sub: "SECURITY",
  },
];

export default data;
