import React from 'react'

function Techtext(props) {
  return (
    <div class='col-10 mx-auto pb-5 mb-3 font-clr-blue p8 explore'>
      Stop wasting time and money on technology.
      <a href='#' target='_blank' class='h-n-link darker p8 font-weight-bold'>
        {props.text}
      </a>
    </div>
  )
}

export default Techtext
