import React, { Component } from "react";
import { Link, animateScroll as scroll } from "react-scroll";
class Scrolltop extends Component {
  state = {
    isTop: true,
  };

  componentDidMount() {
    document.addEventListener("scroll", () => {
      const isTop = window.scrollY < 200;
      if (isTop !== this.state.isTop) {
        this.setState({ isTop });
      }
    });
  }
  render() {
    return (
      <>
        {this.state.isTop ? (
          ""
        ) : (
          <Link
            smooth={true}
            className="scroll-btn d-flex footer__button-top"
            to="header"
          >
            <button className="fa fa-arrow-up arrow-btn m-auto border"></button>
          </Link>
        )}
      </>
    );
  }
}

export default Scrolltop;
