import React from 'react'
import laptop from '../../../assets/About Us Page/Rectangle 83.png'
import laptop_2 from '../../../assets/About Us Page/Rectangle 84.png'
import laptop_3 from '../../../assets/About Us Page/Rectangle 85.png'
import laptop_4 from '../../../assets/About Us Page/Rectangle 87.png'
import laptop_5 from '../../../assets/About Us Page/Rectangle 86.png'
import laptop_6 from '../../../assets/About Us Page/Rectangle 88.png'
function CoreValues(props) {
  return (
    <>
      <div className='row mx-auto bg-light-v pt--80'>
        <div className='col-lg-10 mx-auto'>
          <div className='row mx-auto text-center pb--80'>
            <div className='col-12 upper font-clr-green p3'>core values</div>
            <div className='col-12 upper p9 p-9 darker my-2'>
              DO, BELEIVE & VALUES
            </div>
            <div className='col-12 para-d font-weight-light linee-h-2 p3 p3-15 p-14'>
              We connect with our clients, our communities, and our company. It
              makes us different. It
            </div>
            <div className='col-12 para-d font-weight-light linee-h-2 p3 p3-15 p-14'>
              makes us better.
            </div>
          </div>
        </div>
      </div>
      <div className='row mx-auto bg-light-v overflow text-center align-items-center justify-content-center'>
        <div className='col-xl col-md px-0 d-xl-block d-none my-auto bg-light-v'>
          <div className='row mx-auto my-2 my-xl-3 bg-white'>
            <div className='col-12 px-0 mb-2'>
              <img
                src={laptop}
                width='100%'
                className='large-img-value'
                alt='laptop_img'
              />
            </div>
            <div className='col-8 pl-0 pr-2 d-xl-block d-none mt-auto'>
              <img
                src={laptop_2}
                width='100%'
                className='small-img-value'
                alt='laptop_img'
              />
            </div>
            <div className='col-4 px-0 mt-auto'>
              <img
                src={laptop_3}
                width='100%'
                className='small-img-value'
                alt='laptop_img'
              />
            </div>
          </div>
        </div>
        <div className='col-xl-8 col-md-11 px-0'>
          <div className='row mx-auto h-100'>
            <div className='col-xl bg-white col-md p-4 pt-5 pb-5'>
              <div className='text-center font-weight-bold p4 darker pb-xl-4 pb-2'>
                Our Culture
              </div>
              <div className='p3--15 p3--16 d-flex  darker font-weight-light mb-2'>
                <i className='fa fa-lg mr-3 mr-xl-3 mt-2 fa-check'></i>
                <div className='text-left linee-h-2'>
                  We communicate clearly, because in a world crowded with
                  over-the-top marketing.
                </div>
              </div>
              <div className='p3--15 p3--16 d-flex  darker font-weight-light mb-2'>
                <i className='fa fa-lg mr-3 mr-xl-3 mt-2 fa-check'></i>
                <div className='text-left linee-h-2'>
                  We communicate clearly, because in a world crowded with
                  over-the-top marketing.
                </div>
              </div>
              <div className='p3--15 p3--16 d-flex  darker font-weight-light mb-2'>
                <i className='fa fa-lg mr-3 mr-xl-3 mt-2 fa-check'></i>
                <div className='text-left linee-h-2'>
                  We communicate clearly, because in a world crowded with
                  over-the-top marketing.
                </div>
              </div>
              <div className='row justify-content-center mx-auto'>
                <button className='bg-blue profile-btn mt-xl-3 mt-2 text-white col-11 border-0'>
                  <div className='p16 my-xl-1'>Find out more</div>
                </button>
              </div>
            </div>
            <div className='col-xl col-md p-4 pt-5 pb-5 bg-blue'>
              <div className='text-center font-weight-bold col-12 p4 text-white pb-xl-4 pb-2'>
                Our Commitment
              </div>
              <div className='p3--15 p3--16 d-flex text-white font-weight-light mb-2'>
                <i className='fa fa-lg mr-3 mr-xl-3 mt-2 fa-check'></i>
                <div className='text-left linee-h-2'>
                  We communicate clearly, because in a world crowded with
                  over-the-top marketing.
                </div>
              </div>
              <div className='p3--15 p3--16 d-flex  text-white font-weight-light mb-2'>
                <i className='fa fa-lg mr-3 mr-xl-3 mt-2 fa-check'></i>
                <div className='text-left linee-h-2'>
                  We communicate clearly, because in a world crowded with
                  over-the-top marketing.
                </div>
              </div>
              <div className='p3--15 p3--16 d-flex text-white font-weight-light mb-2'>
                <i className='fa fa-lg mr-3 mr-xl-3 mt-2 fa-check'></i>
                <div className='text-left linee-h-2'>
                  We communicate clearly, because in a world crowded with
                  over-the-top marketing.
                </div>
              </div>
              <div className='row justify-content-center mx-auto'>
                <button className='getin-btn mt-xl-3 mt-2 text-white col-11 border-0'>
                  <div className='p16 my-xl-1 darker'>Find out more</div>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='col-xl col-md px-0  d-xl-block d-none bg-light-v mt--10'>
          <div className='row mx-auto bg-white my-2 my-xl-3'>
            <div className='col-4 px-0'>
              <img
                src={laptop_3}
                width='100%'
                className='small-img-value'
                alt='laptop_img'
              />
            </div>
            <div className='col-8  d-xl-block d-none pr-0 pl-2'>
              <img
                src={laptop_2}
                width='100%'
                className='small-img-value'
                alt='laptop_img'
              />
            </div>
            <div className='col-12 px-0 mt-2'>
              <img
                src={laptop}
                width='100%'
                className='large-img-value'
                alt='laptop_img'
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CoreValues
