import React from 'react'
import team1 from '../../../assets/About Us Page/Rectangle 88.png'
function OurPeople(props) {
  return (
    <div className='bg-white row mx-auto py--80'>
      <div className='col-lg-10 mx-auto'>
        <div className='row mx-auto'>
          <div className='col-12 upper font-clr-green p3'>OUR PEOPLE</div>
          <div className='col-12 col-lg-7 col-md-9 mx-auto upper p9 p-26 darker mt-2'>
            GREAT PRODUCT STARTS WITH A GREAT TEAM
          </div>
          <div className='col-12 col-lg-8 col-md-9 mx-auto para-d font-weight-light p3 p3-14 p-14 mt-2 mb-4'>
            NanoSoft Solution’s leadership team has a long history of success in
            technology, business management, and franchising.
          </div>
          <div className='col-12'>
            <div className='row mt-xl-5 mt-2'>
              <div className='col-xl col-lg-4 col-md-6 pl-lg-0 ml-lg-auto pl-md-5 pl-0 pr-md-1 pr-0'>
                <div className='img-container p-0 grey-image'>
                  <img
                    src={team1}
                    height='250px'
                    className='card-img-top no-radius team-img'
                    alt='Snow'
                  />
                  <div class='bottom-right p14 font-weight-bold mx-auto'>
                    <a href='#'>
                      <i className='fab fa-linkedin-in'></i>
                    </a>
                  </div>
                </div>
                <div className='bg-blue row mx-auto'>
                  <div className='my-auto p-2 text-left'>
                    <div className='p6 font-weight-bold text-white lh-1 my-3 m-2'>
                      Abdullah Akram
                    </div>
                    <div className='p3 lighter font-weight-light lh-1 mb-3 m-2'>
                      Founder & CEO
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xl col-lg-4 col-md-6 mt-2 mt-md-0 pl-0 pr-lg-1 pr-md-5 pr-0'>
                <div className='img-container p-0 grey-image'>
                  <img
                    src={team1}
                    height='250px'
                    className='card-img-top no-radius team-img'
                    alt='Snow'
                  />
                  <div class='bottom-right p14 font-weight-bold mx-auto'>
                    <a href='#'>
                      <i className='fab fa-linkedin-in'></i>
                    </a>
                  </div>
                </div>
                <div className='bg-blue row mx-auto'>
                  <div className='my-auto p-2 text-left'>
                    <div className='p6 font-weight-bold text-white lh-1 my-3 m-2'>
                      Abdullah Akram
                    </div>
                    <div className='p3 lighter font-weight-light lh-1 mb-3 m-2'>
                      Founder & CEO
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xl col-lg-4 col-md-6 mt-2 mt-md-0 pl-lg-0 pl-md-5 pl-0 pr-md-1 pr-0'>
                <div className='img-container p-0 grey-image'>
                  <img
                    src={team1}
                    height='250px'
                    className='card-img-top no-radius team-img'
                    alt='Snow'
                  />
                  <div class='bottom-right p14 font-weight-bold mx-auto'>
                    <a href='#'>
                      <i className='fab fa-linkedin-in'></i>
                    </a>
                  </div>
                </div>
                <div className='bg-blue row mx-auto'>
                  <div className='my-auto p-2 text-left'>
                    <div className='p6 font-weight-bold text-white lh-1 my-3 m-2'>
                      Abdullah Akram
                    </div>
                    <div className='p3 lighter font-weight-light lh-1 mb-3 m-2'>
                      Founder & CEO
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xl col-lg-4 col-md-6 mt-2 mt-md-2 mt-xl-0 pl-0 mll-mdd-5 pr-lg-1 pr-md-5 pr-0'>
                <div className='img-container p-0 grey-image'>
                  <img
                    src={team1}
                    height='250px'
                    className='card-img-top no-radius team-img'
                    alt='Snow'
                  />
                  <div class='bottom-right p14 font-weight-bold mx-auto'>
                    <a href='#'>
                      <i className='fab fa-linkedin-in'></i>
                    </a>
                  </div>
                </div>
                <div className='bg-blue row mx-auto'>
                  <div className='my-auto p-2 text-left'>
                    <div className='p6 font-weight-bold text-white lh-1 my-3 m-2'>
                      Abdullah Akram
                    </div>
                    <div className='p3 lighter font-weight-light lh-1 mb-3 m-2'>
                      Founder & CEO
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xl col-lg-4 col-md-6 mt-2 mt-md-2 mt-xl-0 pl-lg-0 mll-md-5 pl-md-5 pl-0 pr-md-1 pr-0'>
                <div className='img-container p-0 grey-image'>
                  <img
                    src={team1}
                    height='250px'
                    className='card-img-top no-radius team-img'
                    alt='Snow'
                  />
                  <div class='bottom-right p14 font-weight-bold mx-auto'>
                    <a href='#'>
                      <i className='fab fa-linkedin-in'></i>
                    </a>
                  </div>
                </div>
                <div className='bg-blue row mx-auto'>
                  <div className='my-auto p-2 text-left'>
                    <div className='p6 font-weight-bold text-white lh-1 my-3 m-2'>
                      Abdullah Akram
                    </div>
                    <div className='p3 lighter font-weight-light lh-1 mb-3 m-2'>
                      Founder & CEO
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row mx-auto mt-5 mb-3'>
          <div className='col-10 mx-auto font-clr-blue p8 explore'>
            Are you a driven and motivated 1st Line IT Support Engineer?
            <a
              href='#'
              target='_blank'
              class='h-n-link darker pl-1 p8 font-weight-bold'
            >
              Now Hiring
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurPeople
