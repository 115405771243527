import React from 'react'
// import { Navbar, Nav, NavDropdown, Form, FloatingLabel } from "react-bootstrap";
import FloatingLabel from 'react-bootstrap-floating-label'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import ReCAPTCHA from 'react-google-recaptcha'

const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
]
function onChange(value) {
  console.log('Captcha value:', value)
}
function ApplyVanacy(props) {
  return (
    <div className='bg-blue py--80 row mx-auto'>
      <div className='col-lg-10 mx-auto'>
        <div className='row mx-auto'>
          <div className='col-12 upper p9 p9-22 p--19 text-white mb-2'>
            CAN’T FIND THE RIGHT VACANCY?
          </div>
          <div className='col-12 text-white font-weight-light p3 p3-13 mb-5'>
            Send us your CV and we will contact you when there’s a perfect fit.
          </div>
          <div className='col-xl-12 mx-auto bg-white br-15 px-xl-5 py--60'>
            <div className='row m-auto my-xl-5 my-2'>
              <div className='col-lg-6 col-md-12 col-12 mx-auto'>
                <FloatingLabel
                  label='Full Name *'
                  className='float-label'
                  onChange={(event) => console.log(event.target.value)}
                />
              </div>
              <div className='col-lg-6 col-md-12 col-12 mx-auto'>
                <FloatingLabel
                  label='Email *'
                  className='float-label'
                  onChange={(event) => console.log(event.target.value)}
                />
              </div>
            </div>
            <div className='row m-auto my-xl-5 my-2'>
              <div className='col-lg-6 col-md-12 col-12 mx-auto text-left'>
                <Select
                  //   defaultValue={[colourOptions[2], colourOptions[3]]}
                  isMulti
                  name='colors'
                  closeMenuOnSelect={false}
                  options={options}
                  //   components={animatedComponents}
                  className='basic-multi-select'
                  classNamePrefix='select'
                  placeholder='Skills *'
                />
              </div>
              <div className='col-lg-6 col-md-12 col-12 mx-auto'>
                <FloatingLabel
                  label='Linkedin *'
                  className='float-label pt-1'
                  onChange={(event) => console.log(event.target.value)}
                />
              </div>
            </div>
            <div className='row m-auto my-xl-5 my-2'>
              <div className='col-lg-12 col-md-12 col-12 mx-auto'>
                <FloatingLabel
                  label='Cover letter or your message'
                  className='float-label pt-1'
                  onChange={(event) => console.log(event.target.value)}
                />
              </div>
            </div>
            <div className='row mx-auto my-md-5 my-2'>
              <div className='col-lg-12 text-left col-11 mx-auto'>
                <div class='upload-btn-wrapper p-2 py-3 lh-1 d-block'>
                  <button class='border-0 bg-trans p-0 lh-1 helvetica font-16 font-weight-light'>
                    <span>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='20'
                        height='20'
                        viewBox='0 0 26.947 27.732'
                      >
                        <path
                          id='Icon_feather-paperclip'
                          data-name='Icon feather-paperclip'
                          d='M26.328,13.676,15.3,24.7A7.2,7.2,0,0,1,5.112,14.516L16.14,3.488a4.8,4.8,0,0,1,6.792,6.792L11.892,21.308a2.4,2.4,0,1,1-3.4-3.4L18.684,7.736'
                          transform='translate(-1.502 -0.582)'
                          fill='none'
                          stroke='#14a800'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          stroke-width='3'
                        />
                      </svg>
                    </span>
                    <span className='pl-2'>
                      Upload or drag & drop files (5mb)
                    </span>
                  </button>
                  <input type='file' name='myfile' />
                </div>
              </div>
            </div>
            <div className='row my-xl-5 my-2'>
              <div className='col-lg-12 col-11 mx-auto'>
                <div className='row mx-auto'>
                  <div className='col-xl-7'>
                    <div className='d-flex align-items-start'>
                      <div className=''>
                        <div
                          class='btn-group d-flex align-items-start'
                          role='group'
                          aria-label='Basic checkbox toggle button group'
                        >
                          <input
                            type='checkbox'
                            class='regular-checkbox big-checkbox mb-2 mr-2 d-none'
                            id='btncheck1'
                            height='20px'
                          />
                          <label
                            class='grey small mb-0 mr-2'
                            for='btncheck1'
                          ></label>
                        </div>
                      </div>
                      <div className='helvetica font-16 text-left font-weight-light'>
                        <span className='text-danger text-left'>*</span>I give
                        consent to the processing of my personal data uploaded
                        via the contact form above under the terms and
                        conditions of Digimark Privacy Policy (Processing of
                        Candidates Personal Data).
                      </div>
                    </div>
                    <div className='d-flex align-items-start mt-xl-4'>
                      <div className=''>
                        <div
                          class='btn-group d-flex align-items-start'
                          role='group'
                          aria-label='Basic checkbox toggle button group'
                        >
                          <input
                            type='checkbox'
                            class='regular-checkbox big-checkbox mb-2 mr-2 d-none'
                            id='btncheck2'
                            height='20px'
                          />
                          <label
                            class='grey small mb-0 mr-2'
                            for='btncheck2'
                          ></label>
                        </div>
                      </div>
                      <div className='helvetica font-16 text-left font-weight-light'>
                        <span className='text-danger text-left'>*</span>I give
                        *I want to receive commercial communications and
                        marketing information from Digimark by electronic means
                        of communication (including telephone and e-mail).
                      </div>
                    </div>
                  </div>
                  <div className='col-xl pr-3 text-right'>
                    <div className='d-flex'>
                      <div className='ml-auto'>
                        <ReCAPTCHA
                          sitekey='Your client site key'
                          onChange={onChange}
                        />
                      </div>
                    </div>
                    <button className='border-0 bg-parrot mt-xl-3 mt-2 footer-btn px-5 px-xl-5 font-16'>
                      <span className='text-white d-block px-xl-5 py-3'>
                        SEND
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApplyVanacy
