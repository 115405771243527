import React from 'react'
import Slider from 'infinite-react-carousel'
import Carousel from 'react-bootstrap/Carousel'
import background from '../../../assets/banner.jpg'
import Dialougebox from './Dialougebox'
import data from '../../../data'
const CarouselMain = () => (
  <div
    className='carousel-bg'
    style={{
      backgroundImage: `url(${background})`,
      backgroundRepeat: `no-repeat`,
      //   width: `100vw`,
      //   height: `100vh`,
      backgroundSize: `cover`,
    }}
  >
    <Carousel className='' indicators={false}>
      <Carousel.Item className=''>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-5'></div>
            <div className='text-white col-lg-7'>
              <p className='p1 p1-23 font-weight-bold pl-1 mb-0 text-left'>
                {data.map((i) => (
                  <>{i.main}</>
                ))}
              </p>
              <div className='mx-auto'>
                <div class='content p-0'>
                  <div class='content__container p-0'>
                    <ul class='content__container__list text-light px-0'>
                      {data.map((item) => (
                        <li class='content__container__list__item'>
                          {item.sub}
                        </li>
                      ))}
                      <li className='content_container_list_item'></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className='col-3 mb-3 border-solid px-0 rounded'></div>
              <p className='p2 p2-25 pl-1 mb-1 text-left'>
                We help great companies simplify and take control of their IT
              </p>
              <div className='d-flex align-items-center'>
                <Dialougebox />
                <p className='p2 font-xl-16 ml-3 mb-0 text-left border-bottom lh-1 pb-0 pointer'>
                  Get a free consultation
                </p>
              </div>
            </div>
          </div>
        </div>
      </Carousel.Item>
    </Carousel>
  </div>
)

export default CarouselMain
