import React from 'react'
import pc_img from '../../../../assets/expertise/webdevelopment-slide-img.png'
import img_1 from '../../../../assets/expertise/Services Details Page (1)/Project_4383336.svg'
import img_2 from '../../../../assets/expertise/Services Details Page (1)/Interest Percentage_4037495.svg'
import img_3 from '../../../../assets/expertise/Services Details Page (1)/b2b.svg'
import img_4 from '../../../../assets/expertise/Services Details Page (1)/Technology_3761631.svg'

function Overview(props) {
  return (
    <div className='bg-light-v row mx-auto'>
      <div className='col-lg-10 col-md-11 mx-auto bg-white shadow py-5 web-solution'>
        <div className='row mx-auto align-items-center'>
          <div className='col-lg-8 col-md-8 text-left'>
            <h6 class='p3 font-clr-green upper text-left m-0 w-100'>
              OVERVIEW
            </h6>
            <h1 class='p9 p-9 font-clr-blue font-weight-bold mx-auto m-0'>
              GROW YOUR BUSINESS WITH CUSTOM-BUILT WEB SOLUTIONS
            </h1>
            <p className='para-d my-3'>
              A successful digital product is the right fit for your business,
              your customers and the market. No matter the kind of app you are
              looking to build, be it a responsive web app, a website or a
              complex enterprise platform with backend databases, using the
              right technology can help you develop secure and stable web
              solutions that also provide exceptional user experiences.
            </p>
          </div>
          <div className='col-lg-4 col-md-4'>
            <img src={pc_img} width='80%' alt='' />
          </div>
          <div className='col-lg-12 mt-5 mb-2'>
            <div className='row mx-auto'>
              <div className='col-lg-3 col-md-6 pl-0 pr-3 mt-3'>
                <div className='d-flex border-right b-parrot'>
                  <div>
                    <img src={img_1} height='45' alt='' />
                  </div>
                  <div className='text-left px-2 ml-1'>
                    <span className='p6 darker font-weight-bold'>792 +</span>
                    <p className='p3 darker font-weight-light'>
                      Projects successfully delivered since 2016
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 pl-0 pr-3 mt-3'>
                <div className='d-flex border-right b-parrot'>
                  <div>
                    <img src={img_2} height='45' alt='' />
                  </div>
                  <div className='text-left px-2 ml-1'>
                    <span className='p6 darker font-weight-bold'>70 +</span>
                    <p className='p3 darker font-weight-light'>
                      % higher engagement ratios in users
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 pl-0 pr-3 mt-3'>
                <div className='d-flex border-right b-parrot'>
                  <div>
                    <img src={img_3} height='45' alt='' />
                  </div>
                  <div className='text-left px-2 ml-1'>
                    <span className='p6 darker font-weight-bold'>29 M+</span>
                    <p className='p3 darker font-weight-light'>
                      Million people using our apps on a B2B2C model
                    </p>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-6 pl-0 pr-3 mt-3'>
                <div className='d-flex'>
                  <div>
                    <img src={img_4} height='45' alt='' />
                  </div>
                  <div className='text-left px-2 ml-1'>
                    <span className='p6 darker font-weight-bold'>300 +</span>
                    <p className='p3 darker font-weight-light'>
                      Experts skilled in design and engineering
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Overview
