import React, { useEffect } from "react";
import "./components/styles/App.css";
import "./components/styles/animation.css";
import "./components/styles/media.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import Home from "./components/pages/Home";
import About from "./components/pages/About";
import Expertise from "./components/pages/Expertise";
import CaseStudies from "./components/pages/CaseStudies";
import Webdevelopment from "./components/small/Expertisesections/Services/Webdevelopment";
import Semiconductor from "./components/pages/casestudy/Semiconductor";
function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/home" exact component={Home} />
          <Route path="/about" exact component={About} />
          <Route path="/expertise" exact component={Expertise} />
          <Route path="/casestudies" exact component={CaseStudies} />
          <Route path="/semicondcutor" exact component={Semiconductor} />
          <Route
            path="/servicewebdevlopment"
            exact
            component={Webdevelopment}
          />
          <Route path="*">
            <Redirect to="/home" />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
