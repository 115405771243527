import React, { useState } from 'react'
import '../../styles/cards.css'
import ServicesCarousel from './ServicesCarousel'
const Card = () => {
  const [step, setStep] = useState(false)
  const changepage = () => {
    setStep(!step)
  }
  return (
    <div className='c0l-lg-10 mx-auto'>
      <div className='justify-content-center pt--80'>
        <div className=''>
          <div className='p3 p3-15 font-clr-green mb-2 upper'>
            Our EXPERTISE
          </div>
          <div className='font-clr-blue row mx-auto'>
            <h1 className='col-lg-8 col-12 col-md-10 p9 p-9 mx-auto mb-2'>
              STAY UP, STAY RUNNING, STAY PROTECTED
            </h1>
          </div>
          <div class='expertise-btn mx-auto row mt-3'>
            <button
              className={`${
                step ? 'industies-btn' : `services-btn`
              }  py-3 px-lg-4 px-3 serv-dim`}
              onClick={changepage}
            >
              <span
                class={`py-lg-3 py-3 px-lg-4 px-3 p13 ${
                  !step ? 'font-weight-bold' : ''
                }`}
              >
                Services
              </span>
            </button>
            <button
              className={` ${
                step ? 'services-btn' : `industies-btn`
              }  py-2 px-lg-4 px-3 serv-dim`}
              onClick={changepage}
            >
              <span
                class={`py-lg-3 py-2 px-lg-4 px-3 p13 ${
                  step ? 'font-weight-bold' : ''
                }`}
              >
                Industries
              </span>
            </button>
            <div class='jss2 jss3'></div>
          </div>
          {!step ? (
            <>
              <ServicesCarousel />
            </>
          ) : (
            ''
          )}
          {/* <div className="col-10 mx-auto my-3 font-clr-blue">
          IT services built specifically for your business.
          <a href="#" target="_blank" className="h-n-link  font-weight-bold">
            Find your solution
          </a>
        </div> */}
          <div className='col-10 mx-auto pt--80 font-clr-blue mt-4 p8 explore'>
            IT services built specifically for your business.
            <a
              href='#'
              target='_blank'
              className='h-n-link darker p8 font-weight-bold'
            >
              Find your solution
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Card
