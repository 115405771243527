import React from 'react'
import { Form } from 'react-bootstrap'
function JoinTeam(props) {
  return (
    <div className='bg-light-v'>
      <div className='col-lg-10 mx-auto py--80'>
        <div className='row mx-auto'>
          <div className='row mx-auto text-center'>
            <div className='col-12 upper font-clr-green p3'>
              JOIN TEAM DIGIMARK DEVELOPERS!
            </div>
            <div className='col-12 upper p9 darker mt-3 mb-2'>
              CURRENT OPENINGS
            </div>
            <div className='col-12 para-d font-weight-light p3 p3-12 mb-lg-5'>
              Feel fulfilled. Have fun. Help us to shape the future.
            </div>
            <div className='col-12 mx-auto mt-4 '>
              <div className='row mx-auto mb-xl-5'>
                <div className='bg-white col-lg-3 col-md-4 col-12 d-flex br-10 py-2 pl-4 mr-md-2 p18 darker align-items-center'>
                  <i className='fa fa-search darker mr-2'></i>
                  <input
                    type='text'
                    className='form-control outline-none p18 darker border-0 px-0 pt-2'
                    placeholder='Search Role by Keyword'
                  />
                </div>
                <div className='bg-white col-lg-3 col-md-4 col-12 d-flex br-10 py-2 mr-md-2 mt-md-0 mt-2 p18 darker align-items-center'>
                  <Form.Control
                    as='select'
                    className='outline-none border-0 pt-2 p18'
                    aria-label='Default select example'
                  >
                    <option>Select Job Position</option>
                    <option className='py-1' value='1'>
                      One
                    </option>
                    <option className='py-1' value='2'>
                      Two
                    </option>
                    <option className='py-1' value='3'>
                      Three
                    </option>
                  </Form.Control>
                </div>
                <button className='border-0 col-lg-2 col-md-3 col-12 mt-md-0 mt-2 footer-btn px-5'>
                  <span className='text-white upper d-block px-xl-2 py-2'>
                    Search
                  </span>
                </button>
              </div>
              <div class='row mx-auto my-2 mb-4 text-left px-2 mbl-view'>
                <div class='col-lg-4 col-md-4 px-0 darker font-weight-bold p6'>
                  Role
                </div>
                <div class='col-lg-2 col-md-2 px-0 darker font-weight-bold p6'>
                  Date
                </div>
                <div class='col-lg-6 col-md-6 ml-auto px-0 darker text-left font-weight-bold p6'>
                  Description
                </div>
              </div>
              <div class='row mx-auto my-3 bg-white br-5 text-left align-items-center opening-padding'>
                <div class='col-lg-4 col-md-4 px-0 font-clr-green underline font-weight-bold font-16'>
                  Engineer: Observability Applications
                </div>
                <div class='mx-auto col-lg-2 col-md-2 px-0 darker font-weight-bold font-16'>
                  Feb 25, 2020
                </div>
                <div class='col-lg-6 col-md-6 px-0 darker font-weight-bold font-14 font-weight-light'>
                  <div class='col-lg-12 px-0 opening-flex m-0 align-items-center'>
                    <div class='col-md-7 col-lg-8 px-0 mr-2 font-16'>
                      DigiMark Developers is looking for a Scrum Master with the
                      following<div class='small'>Requirements.</div>
                      <div class='small'>
                        • Experience as a scrum master • Understands agile
                        reporting and metrics as well as scrum pr...
                      </div>
                    </div>
                    <button class=' col-md-4 col-lg-3 col-6 px-xl-3 px-2 px-0  button w-fit ml-auto h-auto button--pan case-btn-h hov-btn-color py-2 br-15 helvetica font-weight-lighter'>
                      <span class='d-block lh-1 py-2 p16'>Apply Now</span>
                    </button>
                  </div>
                </div>
              </div>
              <div class='row mx-auto my-3 br-5 text-left align-items-center opening-padding'>
                <div class='col-lg-4 col-md-4 px-0 font-clr-green underline font-weight-bold font-16'>
                  Engineer: Observability Applications
                </div>
                <div class='mx-auto col-lg-2 col-md-2 px-0 darker font-weight-bold font-16'>
                  Feb 25, 2020
                </div>
                <div class='col-lg-6 col-md-6 px-0 darker font-weight-bold font-14 font-weight-light'>
                  <div class='col-lg-12 px-0 opening-flex m-0 align-items-center'>
                    <div class='col-md-7 col-lg-8 px-0 mr-2 font-16'>
                      DigiMark Developers is looking for a Scrum Master with the
                      following<div class='small'>Requirements.</div>
                      <div class='small'>
                        • Experience as a scrum master • Understands agile
                        reporting and metrics as well as scrum pr...
                      </div>
                    </div>
                    <button class=' col-md-4 col-lg-3 col-6 px-xl-3 px-2 px-0  button w-fit ml-auto h-auto button--pan case-btn-h hov-btn-color py-2 br-15 helvetica font-weight-lighter'>
                      <span class='d-block lh-1 py-2 p16'>Apply Now</span>
                    </button>
                  </div>
                </div>
              </div>
              <div class='row mx-auto my-3 bg-white br-5 text-left align-items-center opening-padding'>
                <div class='col-lg-4 col-md-4 px-0 font-clr-green underline font-weight-bold font-16'>
                  Engineer: Observability Applications
                </div>
                <div class='mx-auto col-lg-2 col-md-2 px-0 darker font-weight-bold font-16'>
                  Feb 25, 2020
                </div>
                <div class='col-lg-6 col-md-6 px-0 darker font-weight-bold font-14 font-weight-light'>
                  <div class='col-lg-12 px-0 opening-flex m-0 align-items-center'>
                    <div class='col-md-7 col-lg-8 px-0 mr-2 font-16'>
                      DigiMark Developers is looking for a Scrum Master with the
                      following<div class='small'>Requirements.</div>
                      <div class='small'>
                        • Experience as a scrum master • Understands agile
                        reporting and metrics as well as scrum pr...
                      </div>
                    </div>
                    <button class=' col-md-4 col-lg-3 col-6 px-xl-3 px-2 px-0  button w-fit ml-auto h-auto button--pan case-btn-h hov-btn-color py-2 br-15 helvetica font-weight-lighter'>
                      <span class='d-block lh-1 py-2 p16'>Apply Now</span>
                    </button>
                  </div>
                </div>
              </div>
              <div class='row mx-auto my-3 br-5 text-left align-items-center opening-padding'>
                <div class='col-lg-4 col-md-4 px-0 font-clr-green underline font-weight-bold font-16'>
                  Engineer: Observability Applications
                </div>
                <div class='mx-auto col-lg-2 col-md-2 px-0 darker font-weight-bold font-16'>
                  Feb 25, 2020
                </div>
                <div class='col-lg-6 col-md-6 px-0 darker font-weight-bold font-14 font-weight-light'>
                  <div class='col-lg-12 px-0 opening-flex m-0 align-items-center'>
                    <div class='col-md-7 col-lg-8 px-0 mr-2 font-16'>
                      DigiMark Developers is looking for a Scrum Master with the
                      following<div class='small'>Requirements.</div>
                      <div class='small'>
                        • Experience as a scrum master • Understands agile
                        reporting and metrics as well as scrum pr...
                      </div>
                    </div>
                    <button class=' col-md-4 col-lg-3 col-6 px-xl-3 px-2 px-0  button w-fit ml-auto h-auto button--pan case-btn-h hov-btn-color py-2 br-15 helvetica font-weight-lighter'>
                      <span class='d-block lh-1 py-2 p16'>Apply Now</span>
                    </button>
                  </div>
                </div>
              </div>
              <div className='row mx-auto mt-xl-5'>
                <div className='col-10 mx-auto font-clr-blue mt-xl-5 p8 explore'>
                  We are always on the lookout for talented folk to join our
                  team. Follow us on
                  <a
                    href='#'
                    target='_blank'
                    class='h-n-link darker pl-1 p8 font-weight-bold'
                  >
                    LinkedIn!
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default JoinTeam
