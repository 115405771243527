import React from 'react'

function Technologies(props) {
  return (
    <div className='col-lg-10 mx-auto'>
      <div className='row mx-auto bg-white'>
        <div className='col-xl-9 mx-auto'>
          <div className='row mx-auto text-center pt--80'>
            <div className='col-12 upper font-clr-green p3'>TECHNOLOGIES</div>
            <div className='col-12 col-md-5 col-lg-8 mx-auto upper p9 p-9 darker my-2'>
              LANGUAGES, TOOLS & FRAMEWORKS
            </div>
            <div className='col-12 para-d font-weight-light p3 mb-4'>
              Whatever the field or platform, we make it happen.
            </div>
          </div>
        </div>
      </div>
      <div className='row text-left'>
        <div className='col-lg-12 mt-2 pb--80'>
          <div className='row m-auto justify-content-center'>
            <div className='col-xl col-md col-6 mt-4'>
              <div className='font-weight-bold p6 font-clr-green upper border-bottom py-xl-3'>
                design
              </div>
              <div className='font-weight-light p6 darker border-bottom mt-1 py-xl-3'>
                Photoshop
              </div>
              <div className='font-weight-light p6 darker border-bottom mt-1 py-xl-3'>
                Illustrator
              </div>
              <div className='font-weight-light p6 darker border-bottom mt-1 py-xl-3'>
                Adobe XD
              </div>
              <div className='font-weight-light p6 darker border-bottom mt-1 py-xl-3'>
                After Effect
              </div>
              <div className='font-weight-light p6 darker border-bottom mt-1 py-xl-3'>
                Figma
              </div>
              <div className='font-weight-light p6 darker border-bottom mt-1 py-xl-3'>
                Sketch
              </div>
              <div className='font-weight-light p6 darker border-bottom mt-1 py-xl-3'>
                Invision
              </div>
              <div className='font-weight-light p6 darker mt-1'>Zepline</div>
            </div>
            <div className='col-xl col-md col-6 mt-4'>
              <div className='font-weight-bold p6 font-clr-green upper border-bottom py-xl-3'>
                MOBILE
              </div>
              <div className='font-weight-light p6 darker border-bottom mt-1 py-xl-3'>
                Kotlin
              </div>
              <div className='font-weight-light p6 darker border-bottom mt-1 py-xl-3'>
                Swift
              </div>
              <div className='font-weight-light p6 darker border-bottom mt-1 py-xl-3'>
                Java
              </div>
              <div className='font-weight-light p6 darker border-bottom mt-1 py-xl-3'>
                React Native
              </div>
              <div className='font-weight-light p6 darker border-bottom mt-1 py-xl-3'>
                Flutter
              </div>
              <div className='font-weight-light p6 darker border-bottom mt-1 py-xl-3'>
                Ionic
              </div>
              <div className='font-weight-light p6 darker mt-1'>
                Objective C
              </div>
            </div>
            <div className='col-xl col-md col-6 mt-4'>
              <div className='font-weight-bold p6 font-clr-green upper border-bottom py-xl-3'>
                TECHNOLOGIES
              </div>
              <div className='font-weight-light p6 darker border-bottom mt-1 py-xl-3'>
                PHP
              </div>
              <div className='font-weight-light p6 darker border-bottom mt-1 py-xl-3'>
                Ruby on Rails
              </div>
              <div className='font-weight-light p6 darker border-bottom mt-1 py-xl-3'>
                Phyton
              </div>
              <div className='font-weight-light p6 darker border-bottom mt-1 py-xl-3'>
                Visual Basic
              </div>
              <div className='font-weight-light p6 darker border-bottom mt-1 py-xl-3'>
                SQL
              </div>
              <div className='font-weight-light p6 darker border-bottom mt-1 py-xl-3'>
                JavaScript
              </div>
              <div className='font-weight-light p6 darker border-bottom mt-1 py-xl-3'>
                XML
              </div>
              <div className='font-weight-light p6 darker border-bottom mt-1 py-xl-3'>
                JAVA
              </div>
              <div className='font-weight-light p6 darker border-bottom mt-1 py-xl-3'>
                Swift
              </div>
              <div className='font-weight-light p6 darker mt-1'>C#</div>
            </div>
            <div className='col-xl col-md col-6 mt-4'>
              <div className='font-weight-bold p6 font-clr-green upper border-bottom py-xl-3'>
                FRAMEWORKS
              </div>
              <div className='font-weight-light p6 darker border-bottom mt-1 py-xl-3'>
                Angular JS
              </div>
              <div className='font-weight-light p6 darker border-bottom mt-1 py-xl-3'>
                React JS
              </div>
              <div className='font-weight-light p6 darker border-bottom mt-1 py-xl-3'>
                Vue JS
              </div>
              <div className='font-weight-light p6 darker border-bottom mt-1 py-xl-3'>
                Next JS
              </div>
              <div className='font-weight-light p6 darker border-bottom mt-1 py-xl-3'>
                Node Js
              </div>
              <div className='font-weight-light p6 darker border-bottom mt-1 py-xl-3'>
                .Net
              </div>
              <div className='font-weight-light p6 darker border-bottom mt-1 py-xl-3'>
                Laravel
              </div>
              <div className='font-weight-light p6 darker border-bottom mt-1 py-xl-3'>
                Djanjo
              </div>
              <div className='font-weight-light p6 darker border-bottom mt-1 py-xl-3'>
                Drupal
              </div>
              <div className='font-weight-light p6 darker mt-1'>WordPress</div>
            </div>
            <div className='col-xl col-md col-6 mt-4'>
              <div className='font-weight-bold p6 font-clr-green upper border-bottom py-xl-3'>
                SERVER SIDE
              </div>
              <div className='font-weight-light p6 darker border-bottom mt-1 py-xl-3'>
                LAMP
              </div>
              <div className='font-weight-light p6 darker border-bottom mt-1 py-xl-3'>
                Node.js
              </div>
              <div className='font-weight-light p6 darker border-bottom mt-1 py-xl-3'>
                Mongo
              </div>
              <div className='font-weight-light p6 darker mt-1'>Redls</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Technologies
