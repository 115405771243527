import React from 'react'
import Marquee from 'react-fast-marquee'
import img from '../../../assets/Group.svg'
function ClientsMarquee(props) {
  const clients = [{ path: '../../../assets/Group.svg' }]
  return (
    <div className='col-lg-10 mx-auto'>
      <div className='row mx-auto mt-5'>
        <div className='col-lg-12 border-top mx-auto'></div>
        <div className='col-lg-12 mx-auto py-3 py-xl-4 py-lg-5 my-xl-2'>
          <Marquee>
            <div className='d-flex align-items-center'>
              {Array.from({ length: 20 }, (_, i) => (
                <img src={img} className='mx-5' />
              ))}
            </div>
          </Marquee>
        </div>
      </div>
    </div>
  )
}

export default ClientsMarquee
