import React from 'react'
import banner_icon from '../../../assets/about-us/pexels-photo-140983.png'
function MainBanner(props) {
  return (
    <div>
      <div class='img-container my-0'>
        <img
          src={banner_icon}
          class='card-img-top no-radius about-banner'
          alt='Snow'
        />
        <div className='about-centered darker'>
          <div class='p9 p-9 font-weight-bold mx-auto'>WHO WE ARE</div>
          <div class='font-weight-light mt-3 mx-auto'>
            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, <br />
              sed diam nonumy eirmod tempor.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainBanner
