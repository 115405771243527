import React from 'react'

function WhyUs(props) {
  return (
    <div className='bg-white'>
      <div className='col-lg-10 mx-auto'>
        <div className='row justify-content-center pt--80'>
          <div className='col-lg-12 col-10 mx-auto pb-3 mb-2'>
            <h6 className='p3 font-clr-green upper text-left w-100'>
              WHY CHOOSE US
            </h6>
            <div className='font-clr-blue row mx-auto justify-content-between'>
              <div className='px-0 col-lg-6 col-md-5 text-left'>
                <h1 className='p9 p-9 font-clr-blue font-weight-bold mb-2'>
                  6 REASONS TO PARTNER WITH US
                </h1>
              </div>
              <div className='para-d p--15 col-lg-6 col-md-6 low-op text-left lh-15 mt-2'>
                Here are 6 reasons why you should choose us to build your
                infrastructure, support your people and systems, as well as
                advise you on projects that will reduce your risk.
              </div>
            </div>
          </div>
          <div className='col-lg-12 mb-3'>
            <div className='row mx-auto'>
              <div className='col-lg-4 col-md-6 card-d bg-white text-center pt-5 pb-5 p-4 mt-2'>
                <div class='overlay-box h-icon-auto fill mb-0 mr-auto text-left'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='59.813'
                    height='60'
                    viewBox='0 0 59.813 53'
                  >
                    <g
                      id='conversations-4872_af60b258-251e-41af-b238-dfb706d7b3d4'
                      transform='translate(-2.115 -5.5)'
                    >
                      <path
                        id='layer2'
                        d='M52,20.5a15,15,0,0,0-15-15H17a15,15,0,0,0-5,29.1V46.5l11-11H37a15,15,0,0,0,15-15Z'
                        fill='#8d8ea3'
                      />
                      <path
                        id='layer1'
                        d='M54.4,28A19,19,0,0,1,37,39.5H28.1A11,11,0,0,0,39,49.5h5.9l9.1,9V49a11,11,0,0,0,.4-21Z'
                        fill='#253858'
                      />
                    </g>
                  </svg>
                </div>
                <h5 className='font-weight-bold text-left p17 my-1'>
                  Trustful Communication
                </h5>
                <p className='p15 p--15 para-d my-2 align-items-center lh-2 mx-1 text-left'>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua.
                </p>
              </div>
              <div className='col-lg-4 col-md-6 card-d bg-white text-center pt-5 pb-5 p-4 mt-2'>
                <div class='overlay-box h-icon-auto fill mb-0 mr-auto text-left'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='44'
                    height='60'
                    viewBox='0 0 44 60.2'
                  >
                    <g
                      id='diploma-2983_a6bb0b64-dbc1-431e-ac00-a83597982a0'
                      transform='translate(-10 -1.9)'
                    >
                      <path
                        id='layer2'
                        d='M53.5,20.8l-3.8-3.5,1-5.1-4.9-1.6-1.1-5-5.1.5L36.5,1.9,32,4.5,27.5,1.9,24.4,6.1l-5.1-.5-1.1,5-4.9,1.6,1,5.1-3.8,3.5,3,4.2-2.1,4.7,4.5,2.6v5.2l5.1.6,2.1,4.7L28,41.2l4,3.5,3.9-3.4,4.9,1.6,2.1-4.7,5.1-.6V32.4l4.5-2.6-2-4.8ZM32,33.3a10,10,0,1,1,10-10A10,10,0,0,1,32,33.3Z'
                        fill='#253858'
                      />
                      <path
                        id='layer1'
                        d='M27.2,45.8l-2.8.9-1.2.2a4,4,0,0,1-3.6-2.3L18.4,42,16,41.6,10,56.4l8.8-1.1,5.5,6.8,5-14.5Zm18.4-4-1.2,2.6a4,4,0,0,1-3.6,2.3l-1.2-.2-2.8-.9-2.1,1.9,5,14.5,5.5-6.8L54,56.4,48,41.6Z'
                        fill='#253858'
                      />
                    </g>
                  </svg>
                </div>
                <h5 className='font-weight-bold text-left p17 my-1'>
                  Scailability
                </h5>
                <p className='p15 p--15 para-d my-2 align-items-center lh-2 mx-1 text-left'>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua.
                </p>
              </div>

              <div className='col-lg-4 col-md-6 card-d bg-white text-center pt-5 pb-5 p-4 mt-2'>
                <div class='overlay-box h-icon-auto fill mb-0 mr-auto text-left'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='46.439'
                    height='60'
                    viewBox='0 0 46.439 46.439'
                  >
                    <path
                      id='layer1'
                      d='M25.22,2a23.22,23.22,0,1,0,23.22,23.22A23.22,23.22,0,0,0,25.22,2Zm7.74,15.4A4.644,4.644,0,0,1,37.6,22.046a1.548,1.548,0,1,1-3.1,0,1.548,1.548,0,1,0-3.1,0,1.548,1.548,0,0,1-3.1,0A4.644,4.644,0,0,1,32.96,17.4Zm-15.48,0a4.644,4.644,0,0,1,4.644,4.644,1.548,1.548,0,0,1-3.1,0,1.548,1.548,0,0,0-3.1,0,1.548,1.548,0,1,1-3.1,0A4.644,4.644,0,0,1,17.48,17.4Zm21.44,15.48a15.867,15.867,0,0,1-27.477-.232A1.56,1.56,0,1,1,14.152,31.1a12.771,12.771,0,0,0,22.059.155A1.548,1.548,0,1,1,38.842,32.8Z'
                      transform='translate(-2 -2)'
                      fill='#253858'
                    />
                  </svg>
                </div>
                <h5 className='font-weight-bold text-left p17 my-1'>
                  Cost-Effectiveness
                </h5>
                <p className='p15 p--15 para-d my-2 align-items-center lh-2 mx-1 text-left'>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua.
                </p>
              </div>
              <div className='col-lg-4 col-md-6 card-d bg-white text-center pt-5 pb-5 p-4 mt-2'>
                <div class='overlay-box h-icon-auto fill mb-0 mr-auto text-left'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='62.04'
                    height='64.041'
                    viewBox='0 0 62.04 64.041'
                  >
                    <g
                      id='flag-2979_1fd1d414-4b4f-4887-a94a-493ba8e0b0c7'
                      transform='translate(-0.96 0.04)'
                    >
                      <path
                        id='layer1'
                        d='M11,32H39L33.8,6H5.8L5,1.6a2.04,2.04,0,1,0-4,.8l12,60A2,2,0,0,0,15,64h.4A2,2,0,0,0,17,61.6Z'
                        fill='#253858'
                      />
                      <path
                        id='layer1-2'
                        data-name='layer1'
                        d='M51,29.8,57.8,18H40.3l2.6,13.2,1,4.8H31.7L33,42H63Z'
                        fill='#253858'
                      />
                    </g>
                  </svg>
                </div>
                <h5 className='font-weight-bold text-left p17 my-1'>
                  One-Stop Shop
                </h5>
                <p className='p15 p--15 para-d my-2 align-items-center lh-2 mx-1 text-left'>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua.
                </p>
              </div>
              <div className='col-lg-4 col-md-6 card-d bg-white text-center pt-5 pb-5 p-4 mt-2'>
                <div class='overlay-box h-icon-auto fill mb-0 mr-auto text-left'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='60'
                    height='64.041'
                    viewBox='0 0 60 48'
                  >
                    <g
                      id='source-code-1900_d2c3a8cb-9d49-47a9-aa2d-152f24e446d6'
                      transform='translate(-2 -8)'
                    >
                      <path
                        id='layer2'
                        d='M2,22V56H62V22ZM21.543,44.685a2,2,0,1,1-3.012,2.631l-8.151-9.33,8.153-9.3a2,2,0,1,1,3.008,2.635L15.694,37.99ZM37.857,28.743l-8,20a2,2,0,0,1-3.715-1.486l8-20a2,2,0,1,1,3.715,1.486Zm7.685,18.572a2,2,0,1,1-3.012-2.631l5.85-6.694-5.847-6.673a2,2,0,1,1,3.008-2.635l8.153,9.3Z'
                        fill='#7a7a94'
                      />
                      <path
                        id='layer1'
                        d='M2,8V18H62V8Zm6,7a2,2,0,1,1,2-2A2,2,0,0,1,8,15Zm6,0a2,2,0,1,1,2-2A2,2,0,0,1,14,15Zm6,0a2,2,0,1,1,2-2A2,2,0,0,1,20,15Z'
                        fill='#192a49'
                      />
                    </g>
                  </svg>
                </div>
                <h5 className='font-weight-bold text-left p17 my-1'>
                  Agile Development
                </h5>
                <p className='p15 p--15 para-d my-2 align-items-center lh-2 mx-1 text-left'>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua.
                </p>
              </div>

              <div className='col-lg-4 col-md-6 card-d bg-white text-center pt-5 pb-5 p-4 mt-2'>
                <div class='overlay-box h-icon-auto fill mb-0 mr-auto text-left'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='47.21'
                    height='64.041'
                    viewBox='0 0 47.21 65.535'
                  >
                    <g
                      id='noun_customer_840497'
                      transform='translate(-14.343 -0.499)'
                    >
                      <path
                        id='Path_105'
                        data-name='Path 105'
                        d='M57.951,59.794a1.635,1.635,0,0,1-2.144,2.051l-5.135-2.118a1.476,1.476,0,0,0-1.846.662L46.1,65.2a1.635,1.635,0,0,1-2.971-.271L39.428,54.2a9.788,9.788,0,0,0,6.485-3.971.324.324,0,0,1,.258-.132h.1a9.675,9.675,0,0,0,8.04-.827ZM29.965,50.2a.318.318,0,0,0-.258-.132h-.1a9.675,9.675,0,0,1-8.04-.827l-3.64,10.549a1.635,1.635,0,0,0,2.144,2.051l5.142-2.118a1.476,1.476,0,0,1,1.846.662L29.787,65.2a1.635,1.635,0,0,0,2.971-.271l3.7-10.714a9.788,9.788,0,0,1-6.492-4.01Zm30.567-28.2a5.049,5.049,0,0,0,0,6.029A5.049,5.049,0,0,1,57.991,35.9h0a5.049,5.049,0,0,0-3.54,4.877,5.036,5.036,0,0,1-6.664,4.838,5.043,5.043,0,0,0-5.731,1.86,5.049,5.049,0,0,1-8.232,0,5.043,5.043,0,0,0-5.731-1.86,5.036,5.036,0,0,1-6.664-4.838,5.049,5.049,0,0,0-3.54-4.877,5.049,5.049,0,0,1-2.541-7.829,5.049,5.049,0,0,0,0-6.029A5.049,5.049,0,0,1,17.888,14.2a5.049,5.049,0,0,0,3.54-4.877A5.036,5.036,0,0,1,28.092,4.49a5.043,5.043,0,0,0,1.615.265,5.049,5.049,0,0,0,4.116-2.131,5.049,5.049,0,0,1,8.232,0,5.049,5.049,0,0,0,4.116,2.131,5.043,5.043,0,0,0,1.615-.265,5.036,5.036,0,0,1,6.664,4.838,5.049,5.049,0,0,0,3.54,4.877h0a5.049,5.049,0,0,1,2.541,7.8ZM28.476,19.281a3.229,3.229,0,1,0,3.229-3.229A3.229,3.229,0,0,0,28.476,19.281Zm17.206,7.478H30.2a1.542,1.542,0,0,0-1.529,1.807,9.437,9.437,0,0,0,18.529,0,1.542,1.542,0,0,0-1.529-1.807Zm1.727-7.478a3.226,3.226,0,1,1-.007,0Z'
                        transform='translate(0)'
                        fill='#253858'
                      />
                    </g>
                  </svg>
                </div>
                <h5 className='font-weight-bold text-left p17 my-1'>
                  100% Satisfaction Guarantee
                </h5>
                <p className='p15 p--15 para-d my-2 align-items-center lh-2 mx-1 text-left'>
                  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                  diam nonumy eirmod tempor invidunt ut labore et dolore magna
                  aliquyam erat, sed diam voluptua.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhyUs
