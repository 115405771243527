import React, { useState } from 'react'

function Joining(props) {
  const [tabs, setTabs] = useState(1)

  return (
    <div className='bg-light-v'>
      <div className='col-lg-10 mx-auto'>
        <div className='py--80' id='careers'>
          <div className='row mx-auto text-center pb-xxl-5'>
            <div className='col-12 upper font-clr-green mb-3 p3'>
              JOINING POCCESS
            </div>
            <div className='col-12 upper p9 p-9 darker my-2'>
              HOW TO GET INTO DIGIMARK DEVELOPERS
            </div>
            <div className='col-12 para-d font-weight-light mt-2 p3 p3-12'>
              Feel fulfilled. Have fun. Help us to shape the future.
            </div>
          </div>
          <div className='row mx-auto text-left'>
            <div className='my-xl-4 my-2 col-xl col-md col-2'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='64.133 '
                height='70'
                viewBox='0 0 64.133 80.48'
                onClick={() => setTabs(1)}
                className='pointer svg-resp'
              >
                <g
                  id='noun_Resume_2768441'
                  className={`${tabs == 1 ? 'fill-green' : 'un-fill'}`}
                  transform='translate(-13)'
                >
                  <path
                    id='Path_6022'
                    data-name='Path 6022'
                    d='M27.257,39.532a1.258,1.258,0,0,0,1.258-1.257v-4.2a3.814,3.814,0,0,1,2.516-3.741,7.594,7.594,0,0,1-2.073-1.761A6.327,6.327,0,0,0,26,34.079v4.195a1.258,1.258,0,0,0,1.257,1.258Z'
                    transform='translate(-4.825 -10.609)'
                    fill='#81b441'
                  />
                  <path
                    id='Path_6023'
                    data-name='Path 6023'
                    d='M48.514,34.079v4.195a1.258,1.258,0,0,0,2.515,0V34.079a6.327,6.327,0,0,0-2.958-5.5A7.594,7.594,0,0,1,46,30.338a3.814,3.814,0,0,1,2.516,3.741Z'
                    transform='translate(-12.25 -10.609)'
                    fill='#81b441'
                  />
                  <path
                    id='Path_6024'
                    data-name='Path 6024'
                    d='M37.03,23.06A5.03,5.03,0,1,0,32,18.03a5.03,5.03,0,0,0,5.03,5.03Zm0-7.545a2.515,2.515,0,1,1-2.515,2.515A2.515,2.515,0,0,1,37.03,15.515Z'
                    transform='translate(-7.054 -4.826)'
                    fill='#81b441'
                  />
                  <path
                    id='Path_6025'
                    data-name='Path 6025'
                    d='M73.36,0H16.773A3.777,3.777,0,0,0,13,3.773V76.708a3.777,3.777,0,0,0,3.773,3.773H73.36a3.777,3.777,0,0,0,3.773-3.773s0-28.735,0-46.969V3.773A3.777,3.777,0,0,0,73.36,0Zm1.258,76.708a1.259,1.259,0,0,1-1.258,1.257H16.773a1.259,1.259,0,0,1-1.258-1.257V3.773a1.259,1.259,0,0,1,1.258-1.258H73.36a1.259,1.259,0,0,1,1.258,1.258Z'
                    fill='#81b441'
                  />
                  <path
                    id='Path_6026'
                    data-name='Path 6026'
                    d='M86.264,26H64.258a1.258,1.258,0,1,0,0,2.515H86.264a1.258,1.258,0,1,0,0-2.515Z'
                    transform='translate(-18.562 -9.652)'
                    fill='#81b441'
                  />
                  <path
                    id='Path_6027'
                    data-name='Path 6027'
                    d='M86.264,18H64.258a1.258,1.258,0,1,0,0,2.515H86.264a1.258,1.258,0,1,0,0-2.515Z'
                    transform='translate(-18.562 -6.682)'
                    fill='#81b441'
                  />
                  <path
                    id='Path_6028'
                    data-name='Path 6028'
                    d='M72.528,57H27.258a1.258,1.258,0,0,0,0,2.515h45.27a1.258,1.258,0,0,0,0-2.515Z'
                    transform='translate(-4.826 -21.161)'
                    fill='#81b441'
                  />
                  <path
                    id='Path_6029'
                    data-name='Path 6029'
                    d='M72.528,65H27.258a1.258,1.258,0,0,0,0,2.515h45.27a1.258,1.258,0,0,0,0-2.515Z'
                    transform='translate(-4.826 -24.131)'
                    fill='#81b441'
                  />
                  <path
                    id='Path_6030'
                    data-name='Path 6030'
                    d='M72.528,73H27.258a1.258,1.258,0,0,0,0,2.515h45.27a1.258,1.258,0,0,0,0-2.515Z'
                    transform='translate(-4.826 -27.101)'
                    fill='#81b441'
                  />
                  <path
                    id='Path_6031'
                    data-name='Path 6031'
                    d='M72.528,85H27.258a1.258,1.258,0,0,0,0,2.515h45.27a1.258,1.258,0,0,0,0-2.515Z'
                    transform='translate(-4.826 -31.556)'
                    fill='#81b441'
                  />
                  <path
                    id='Path_6032'
                    data-name='Path 6032'
                    d='M72.528,93H27.258a1.258,1.258,0,0,0,0,2.515h45.27a1.258,1.258,0,0,0,0-2.515Z'
                    transform='translate(-4.826 -34.526)'
                    fill='#81b441'
                  />
                  <path
                    id='Path_6033'
                    data-name='Path 6033'
                    d='M47.378,101H27.258a1.258,1.258,0,1,0,0,2.515h20.12a1.258,1.258,0,0,0,0-2.515Z'
                    transform='translate(-4.826 -37.496)'
                    fill='#81b441'
                  />
                  <path
                    id='Path_6034'
                    data-name='Path 6034'
                    d='M86.264,34H64.258a1.258,1.258,0,0,0,0,2.515H86.264a1.258,1.258,0,0,0,0-2.515Z'
                    transform='translate(-18.562 -12.622)'
                    fill='#81b441'
                  />
                  <path
                    id='Path_6035'
                    data-name='Path 6035'
                    d='M74.318,44.515a1.258,1.258,0,0,0,0-2.515H64.258a1.258,1.258,0,0,0,0,2.515Z'
                    transform='translate(-18.562 -15.592)'
                    fill='#81b441'
                  />
                  <path
                    id='Path_6036'
                    data-name='Path 6036'
                    d='M91.886,111h-.629a1.258,1.258,0,1,0,0,2.515h.629a1.258,1.258,0,1,0,0-2.515Z'
                    transform='translate(-28.586 -41.208)'
                    fill='#81b441'
                  />
                  <path
                    id='Path_6037'
                    data-name='Path 6037'
                    d='M98.886,111h-.629a1.258,1.258,0,1,0,0,2.515h.629a1.258,1.258,0,0,0,0-2.515Z'
                    transform='translate(-31.185 -41.208)'
                    fill='#81b441'
                  />
                  <path
                    id='Path_6038'
                    data-name='Path 6038'
                    d='M84.886,111h-.629a1.258,1.258,0,1,0,0,2.515h.629a1.258,1.258,0,1,0,0-2.515Z'
                    transform='translate(-25.987 -41.208)'
                    fill='#81b441'
                  />
                </g>
              </svg>
            </div>
            <div className='my-xl-4 my-2 col-xl col-md col-2'>
              <svg
                onClick={() => setTabs(2)}
                id='interview'
                xmlns='http://www.w3.org/2000/svg'
                width='80.37'
                height='70'
                viewBox='0 0 80.37 80.377'
                className={`svg-resp ${
                  tabs == 2 ? 'fill-green' : 'un-fill'
                } pointer`}
              >
                <path
                  id='Path_6042'
                  data-name='Path 6042'
                  d='M11.445,185.867c.479.478.959.938,1.463,1.425.471.492.937.974,1.419,1.456,5.97,5.924,11.639,9.312,17.839,10.662a31.21,31.21,0,0,0,6.619.723,23.856,23.856,0,0,0,17.076-6.563,7.737,7.737,0,0,0,0-10.948l-6.1-6.1a7.738,7.738,0,0,0-10.946,0l-4.233,4.233a1.32,1.32,0,0,1-1.7.162,57.579,57.579,0,0,1-13.618-13.608V167.3a1.319,1.319,0,0,1,.169-1.692l4.233-4.234a7.751,7.751,0,0,0,0-10.948l-6.1-6.1a7.739,7.739,0,0,0-10.947,0c-5.655,5.645-7.836,14.5-5.845,23.694,1.353,6.2,4.74,11.869,10.67,17.843ZM8.51,146.222a5.062,5.062,0,0,1,7.159,0l6.09,6.1a5.069,5.069,0,0,1,0,7.16l-4.233,4.233a4.018,4.018,0,0,0-.442,5.16A60.086,60.086,0,0,0,31.3,183.093a3.97,3.97,0,0,0,5.166-.438l4.232-4.233a5.182,5.182,0,0,1,7.159,0l6.1,6.1a5.061,5.061,0,0,1,0,7.16c-5,5-12.94,6.91-21.232,5.112-5.68-1.238-10.929-4.4-16.518-9.945-.468-.468-.92-.937-1.473-1.5-.476-.458-.937-.908-1.407-1.37-5.549-5.592-8.706-10.842-9.946-16.521-1.785-8.295.124-16.233,5.126-21.234Zm0,0'
                  transform='translate(-0.052 -119.765)'
                  fill='#1d4354'
                />
                <path
                  id='Path_6043'
                  data-name='Path 6043'
                  d='M39.713,218.643a1.339,1.339,0,0,0,1.274-1.764c-2.5-7.5.487-10.8.633-10.952a1.339,1.339,0,0,0-1.9-1.883c-.181.178-4.341,4.484-1.274,13.683a1.34,1.34,0,0,0,1.271.916Zm0,0'
                  transform='translate(-31.469 -171.697)'
                  fill='#1d4354'
                />
                <path
                  id='Path_6044'
                  data-name='Path 6044'
                  d='M213.885,448.572a20.079,20.079,0,0,0,6.355,1.125,10.535,10.535,0,0,0,7.328-2.4,1.342,1.342,0,0,0-1.888-1.906c-.134.134-3.425,3.145-10.952.635a1.341,1.341,0,0,0-.848,2.545Zm0,0'
                  transform='translate(-179.545 -375.177)'
                  fill='#1d4354'
                />
                <path
                  id='Path_6045'
                  data-name='Path 6045'
                  d='M275.854,74.755h16.072a2.679,2.679,0,0,0,2.679-2.679V62.7a6.7,6.7,0,0,0-4.972-6.444,8.036,8.036,0,1,0-11.486,0,6.7,6.7,0,0,0-4.972,6.444v9.375a2.679,2.679,0,0,0,2.679,2.679ZM283.89,45.29a5.357,5.357,0,1,1-5.357,5.357A5.357,5.357,0,0,1,283.89,45.29ZM275.854,62.7a4.018,4.018,0,0,1,4.018-4.018h8.037a4.018,4.018,0,0,1,4.018,4.018v9.375H275.854Zm0,0'
                  transform='translate(-230.307 -35.914)'
                  fill='#1d4354'
                />
                <path
                  id='Path_6046'
                  data-name='Path 6046'
                  d='M171.192,50.11a1.986,1.986,0,0,0,1.582.79,2.033,2.033,0,0,0,.893-.209c5.71-2.812,10.278-4.362,13.185-4.492l.018.007a28.937,28.937,0,0,0,10.689,2.01c14.77,0,26.787-10.815,26.787-24.108S212.331,0,197.56,0s-26.787,10.815-26.787,24.109a22.16,22.16,0,0,0,5.216,14.242,20.135,20.135,0,0,1-4.708,9.209,1.993,1.993,0,0,0-.088,2.55ZM178.61,38.9a2.635,2.635,0,0,0-.561-2.216,19.7,19.7,0,0,1-4.6-12.574c0-11.816,10.816-21.43,24.109-21.43s24.109,9.614,24.109,21.43S210.854,45.537,197.56,45.537a26.247,26.247,0,0,1-9.7-1.824,2.706,2.706,0,0,0-1.09-.189c-2.863.126-6.79,1.315-11.693,3.538A22.5,22.5,0,0,0,178.61,38.9Zm0,0'
                  transform='translate(-143.978 0)'
                  fill='#1d4354'
                />
              </svg>
            </div>
            <div className='my-xl-4 my-2 col-xl col-md col-2'>
              <svg
                onClick={() => setTabs(3)}
                id='noun_test_1221430'
                xmlns='http://www.w3.org/2000/svg'
                width='63.805 '
                height='70'
                viewBox='0 0 63.805 80.644'
                className='pointer svg-resp'
              >
                <g
                  className={`${tabs == 3 ? 'fill-green' : 'un-fill'}`}
                  id='Group_3691'
                  data-name='Group 3691'
                >
                  <path
                    id='Path_6011'
                    data-name='Path 6011'
                    d='M47.09,958.362a1.779,1.779,0,0,0-.888.229l-3.923,2.177H42.25l-3.522,1.947H25.612c-5.666,0-10.424,4.4-10.424,9.909V1029.1c0,5.5,4.758,9.908,10.424,9.908H68.569c5.666,0,10.424-4.408,10.424-9.908V972.623c0-5.508-4.758-9.909-10.424-9.909H55.453L51.9,960.767l-3.923-2.176a1.88,1.88,0,0,0-.888-.229Zm-21.478,8.019H38.728l3.522,1.976,3.952,2.177a1.835,1.835,0,0,0,1.776,0l3.923-2.177,3.551-1.976H68.569a6.523,6.523,0,0,1,6.759,6.243V1029.1a6.53,6.53,0,0,1-6.759,6.243H25.612a6.53,6.53,0,0,1-6.759-6.243V972.623A6.523,6.523,0,0,1,25.612,966.381Z'
                    transform='translate(-15.188 -958.362)'
                    fill='#1d4354'
                  />
                  <path
                    id='Path_6012'
                    data-name='Path 6012'
                    d='M29.034,979.512a1.833,1.833,0,1,0,0,3.666H53.969a1.833,1.833,0,1,0,0-3.666Z'
                    transform='translate(-16.19 -960.13)'
                    fill='#1d4354'
                  />
                  <path
                    id='Path_6013'
                    data-name='Path 6013'
                    d='M29.034,992.532a1.833,1.833,0,1,0,0,3.665H53.969a1.833,1.833,0,1,0,0-3.665Z'
                    transform='translate(-16.19 -961.218)'
                    fill='#1d4354'
                  />
                  <path
                    id='Path_6014'
                    data-name='Path 6014'
                    d='M29.034,1005.56a1.914,1.914,0,0,0-.709.132,1.833,1.833,0,0,0,.709,3.534H53.969a1.9,1.9,0,0,0,.709-.132,1.833,1.833,0,0,0-.709-3.534Z'
                    transform='translate(-16.19 -962.307)'
                    fill='#1d4354'
                  />
                  <path
                    id='Path_6015'
                    data-name='Path 6015'
                    d='M29.034,1018.58a1.914,1.914,0,0,0-.709.132,1.833,1.833,0,0,0,.709,3.534H53.969a1.892,1.892,0,0,0,.709-.132,1.833,1.833,0,0,0,0-3.4,1.831,1.831,0,0,0-.709-.132Z'
                    transform='translate(-16.19 -963.395)'
                    fill='#1d4354'
                  />
                  <path
                    id='Path_6016'
                    data-name='Path 6016'
                    d='M29.034,1031.6a1.911,1.911,0,0,0-.709.132,1.833,1.833,0,0,0,.709,3.534H53.969a1.9,1.9,0,0,0,.709-.133,1.833,1.833,0,0,0,0-3.4,1.818,1.818,0,0,0-.709-.132Z'
                    transform='translate(-16.19 -964.483)'
                    fill='#1d4354'
                  />
                  <path
                    id='Path_6017'
                    data-name='Path 6017'
                    d='M69.023,976.593a1.832,1.832,0,0,0-1.277.514L66.4,978.368l-1.344-1.261a1.833,1.833,0,1,0-2.508,2.673l1.174,1.1-1.174,1.1a1.833,1.833,0,1,0,2.508,2.673l1.344-1.261,1.344,1.261a1.833,1.833,0,1,0,2.508-2.673l-1.174-1.1,1.174-1.1a1.833,1.833,0,0,0-1.231-3.187Z'
                    transform='translate(-19.096 -959.885)'
                    fill='#1d4354'
                  />
                  <path
                    id='Path_6018'
                    data-name='Path 6018'
                    d='M69.023,1029.23a1.827,1.827,0,0,0-1.277.514L66.4,1031l-1.344-1.261a1.836,1.836,0,0,0-1.32-.517,1.92,1.92,0,0,0-.705.159,1.833,1.833,0,0,0-.484,3.031l1.174,1.1-1.174,1.1a1.833,1.833,0,0,0,1.189,3.19,1.777,1.777,0,0,0,.712-.126,1.829,1.829,0,0,0,.608-.391l1.344-1.261,1.344,1.261a1.833,1.833,0,0,0,1.319.517,1.9,1.9,0,0,0,.705-.159,1.833,1.833,0,0,0,.484-3.031l-1.174-1.1,1.174-1.1a1.833,1.833,0,0,0-1.231-3.187Z'
                    transform='translate(-19.096 -964.285)'
                    fill='#1d4354'
                  />
                  <path
                    id='Path_6019'
                    data-name='Path 6019'
                    d='M69.023,1003.549a1.834,1.834,0,0,0-1.277.515l-1.344,1.261-1.344-1.261a1.833,1.833,0,0,0-1.32-.517,1.907,1.907,0,0,0-.705.159,1.833,1.833,0,0,0-.484,3.031l1.174,1.1-1.174,1.1a1.833,1.833,0,0,0,1.189,3.19,1.774,1.774,0,0,0,.712-.126,1.837,1.837,0,0,0,.608-.391l1.344-1.261,1.344,1.261a1.831,1.831,0,0,0,1.319.517,1.9,1.9,0,0,0,.705-.159,1.833,1.833,0,0,0,.484-3.031l-1.174-1.1,1.174-1.1a1.833,1.833,0,0,0-1.231-3.187Z'
                    transform='translate(-19.096 -962.138)'
                    fill='#1d4354'
                  />
                  <path
                    id='Path_6020'
                    data-name='Path 6020'
                    d='M69.661,988.879a1.833,1.833,0,0,0-1.642.892l-1.827,2.967a1.833,1.833,0,0,0-3.158,1.7l.889,3.116a1.833,1.833,0,0,0,3.323.458l3.895-6.323a1.833,1.833,0,0,0-1.479-2.815Z'
                    transform='translate(-19.18 -960.912)'
                    fill='#1d4354'
                  />
                  <path
                    id='Path_6021'
                    data-name='Path 6021'
                    d='M69.659,1015.29a1.833,1.833,0,0,0-1.641.894l-1.829,2.973a1.833,1.833,0,0,0-3.156,1.706l.889,3.116a1.833,1.833,0,0,0,3.323.457l3.895-6.333a1.833,1.833,0,0,0-1.481-2.814Z'
                    transform='translate(-19.18 -963.12)'
                    fill='#1d4354'
                  />
                </g>
              </svg>
            </div>
            <div className='my-xl-4 my-2 col-xl col-md col-2'>
              <svg
                id='noun_discussion_2315300'
                xmlns='http://www.w3.org/2000/svg'
                width='91.457 '
                height='70'
                viewBox='0 0 91.457 80.701'
                onClick={() => setTabs(4)}
                className='pointer svg-resp'
              >
                <g
                  className={`${tabs == 4 ? 'fill-green' : 'un-fill'}`}
                  id='Group_3694'
                  data-name='Group 3694'
                >
                  <path
                    id='Path_6047'
                    data-name='Path 6047'
                    d='M32.356,135.044,27.5,134.3l-1.261-13.351a10.759,10.759,0,1,0-21.473,1.372L6.1,136.305a10.722,10.722,0,0,0,6.9,9.383,7,7,0,0,0,3.375.7l10.2.482a1.832,1.832,0,0,0,1.929-1.743,1.855,1.855,0,0,0-1.743-1.928l-10.31-.482h-.185a3.218,3.218,0,0,1-1.669-.334.527.527,0,0,0-.223-.074,7.13,7.13,0,0,1-4.636-6.268l-1.3-14.019a7.077,7.077,0,1,1,14.13-.816L23.9,135.193v.63a1.81,1.81,0,0,0,1.558,1.854l6.342,1a1.56,1.56,0,0,1,1.335,1.558V155.7a1.854,1.854,0,1,0,3.709,0V140.237A5.362,5.362,0,0,0,32.356,135.044Z'
                    transform='translate(-4.743 -76.855)'
                    fill='#1d4354'
                  />
                  <path
                    id='Path_6048'
                    data-name='Path 6048'
                    d='M19.143,66.785A9.643,9.643,0,1,0,9.5,57.143,9.671,9.671,0,0,0,19.143,66.785Zm0-15.614a5.971,5.971,0,1,1-5.971,5.971A5.967,5.967,0,0,1,19.143,51.172Z'
                    transform='translate(-7.736 -37.004)'
                    fill='#1d4354'
                  />
                  <path
                    id='Path_6049'
                    data-name='Path 6049'
                    d='M186.755,110.864a10.763,10.763,0,0,0-11.386,10.013L174.109,134.3l-4.858.742a5.261,5.261,0,0,0-4.45,5.192V155.7a1.86,1.86,0,0,0,1.854,1.854,1.836,1.836,0,0,0,1.854-1.854V140.237a1.588,1.588,0,0,1,1.335-1.558l6.342-1a1.81,1.81,0,0,0,1.558-1.854v-.556l1.335-14.093a7.076,7.076,0,0,1,14.13.779l-1.335,14.093a7.078,7.078,0,0,1-4.6,6.193.527.527,0,0,1-.223.074,3.218,3.218,0,0,1-1.669.334H185.2l-10.31.482a1.83,1.83,0,0,0-1.743,1.928,1.808,1.808,0,0,0,1.929,1.743l10.2-.482a7.09,7.09,0,0,0,3.375-.7,10.8,10.8,0,0,0,6.9-9.346l1.335-14.093A10.76,10.76,0,0,0,186.755,110.864Z'
                    transform='translate(-105.44 -76.855)'
                    fill='#1d4354'
                  />
                  <path
                    id='Path_6050'
                    data-name='Path 6050'
                    d='M204.143,66.785a9.643,9.643,0,1,0-9.643-9.643A9.671,9.671,0,0,0,204.143,66.785Zm0-15.614a5.971,5.971,0,1,1-5.971,5.971A5.967,5.967,0,0,1,204.143,51.172Z'
                    transform='translate(-124.125 -37.004)'
                    fill='#1d4354'
                  />
                  <path
                    id='Path_6051'
                    data-name='Path 6051'
                    d='M114.751,44.419V21.166a1.979,1.979,0,0,0-1.966-1.966H78.666A1.979,1.979,0,0,0,76.7,21.166V44.419a1.979,1.979,0,0,0,1.966,1.966H88.2l6.231,6.231a1.793,1.793,0,0,0,2.6,0l6.231-6.231h9.531A1.955,1.955,0,0,0,114.751,44.419Zm-3.672-1.706h-8.6a1.74,1.74,0,0,0-1.3.556l-5.452,5.452-5.452-5.489a1.889,1.889,0,0,0-1.3-.556h-8.6V22.909h30.708Z'
                    transform='translate(-50.013 -19.2)'
                    fill='#1d4354'
                  />
                </g>
              </svg>
            </div>
            <div className='my-xl-4 my-2 col-xl col-md col-2'>
              <svg
                id='noun_employment_1171184'
                xmlns='http://www.w3.org/2000/svg'
                width='81.082'
                height='80.087'
                viewBox='0 0 81.082 80.087'
                onClick={() => setTabs(5)}
                className={`svg-resp ${
                  tabs == 5 ? 'fill-green' : 'un-fill'
                } pointer`}
              >
                <rect
                  id='Rectangle_788'
                  data-name='Rectangle 788'
                  width='4.228'
                  height='4.228'
                  transform='translate(38.385)'
                  fill='#1d4354'
                />
                <rect
                  id='Rectangle_789'
                  data-name='Rectangle 789'
                  width='4.228'
                  height='4.228'
                  transform='translate(38.385 75.859)'
                  fill='#1d4354'
                />
                <rect
                  id='Rectangle_790'
                  data-name='Rectangle 790'
                  width='4.228'
                  height='4.228'
                  transform='translate(10.612 63.672)'
                  fill='#1d4354'
                />
                <rect
                  id='Rectangle_791'
                  data-name='Rectangle 791'
                  width='4.228'
                  height='4.228'
                  transform='translate(64.998 63.672)'
                  fill='#1d4354'
                />
                <rect
                  id='Rectangle_792'
                  data-name='Rectangle 792'
                  width='4.228'
                  height='4.228'
                  transform='translate(10.612 11.607)'
                  fill='#1d4354'
                />
                <rect
                  id='Rectangle_793'
                  data-name='Rectangle 793'
                  width='4.228'
                  height='4.228'
                  transform='translate(64.998 11.607)'
                  fill='#1d4354'
                />
                <rect
                  id='Rectangle_794'
                  data-name='Rectangle 794'
                  width='4.228'
                  height='4.228'
                  transform='translate(0 37.556)'
                  fill='#1d4354'
                />
                <rect
                  id='Rectangle_795'
                  data-name='Rectangle 795'
                  width='4.228'
                  height='4.228'
                  transform='translate(76.854 37.556)'
                  fill='#1d4354'
                />
                <path
                  id='Path_6052'
                  data-name='Path 6052'
                  d='M9.169,24.249A37.479,37.479,0,0,0,3.283,40.084l-.083.5H5.687l.083-.415a37.487,37.487,0,0,1,6.052-15.5l.5-.663h-2.9Z'
                  transform='translate(-1.459 -5.512)'
                  fill='#1d4354'
                />
                <path
                  id='Path_6053'
                  data-name='Path 6053'
                  d='M5.587,55.515,5.5,55.1H3.1l.083.5a39.084,39.084,0,0,0,5.8,16.25l.166.166h2.9l-.5-.663A37.606,37.606,0,0,1,5.587,55.515Z'
                  transform='translate(-1.442 -10.828)'
                  fill='#1d4354'
                />
                <path
                  id='Path_6054'
                  data-name='Path 6054'
                  d='M92.367,40.284l.083.415h2.487l-.083-.5a39.83,39.83,0,0,0-5.886-15.835L88.8,24.2H85.9l.5.663A37.709,37.709,0,0,1,92.367,40.284Z'
                  transform='translate(-15.596 -5.546)'
                  fill='#1d4354'
                />
                <path
                  id='Path_6055'
                  data-name='Path 6055'
                  d='M92.55,55.1l-.083.415A37.193,37.193,0,0,1,86.5,71.35L86,72.1h2.9l.166-.166a37.575,37.575,0,0,0,5.8-16.25l.083-.5-2.4-.083Z'
                  transform='translate(-15.613 -10.828)'
                  fill='#1d4354'
                />
                <path
                  id='Path_6056'
                  data-name='Path 6056'
                  d='M70.921,11.308l.083.083h4.145l-1.078-.829A37.805,37.805,0,0,0,56,3.183L55.5,3.1V5.587l.415.083A35.215,35.215,0,0,1,70.921,11.308Z'
                  transform='translate(-10.399 -1.939)'
                  fill='#1d4354'
                />
                <path
                  id='Path_6057'
                  data-name='Path 6057'
                  d='M21.678,10.562l-1.078.829h4.145l.083-.083A35.89,35.89,0,0,1,40,5.67l.415-.083V3.1l-.5.083A38.394,38.394,0,0,0,21.678,10.562Z'
                  transform='translate(-4.433 -1.939)'
                  fill='#1d4354'
                />
                <path
                  id='Path_6058'
                  data-name='Path 6058'
                  d='M71.318,86.783a37.224,37.224,0,0,1-15.5,5.886l-.415.083v2.487l.5-.083a38.805,38.805,0,0,0,18.488-7.627l1.078-.829-4.145.083Z'
                  transform='translate(-10.382 -16.23)'
                  fill='#1d4354'
                />
                <path
                  id='Path_6059'
                  data-name='Path 6059'
                  d='M24.428,86.783l-.166-.083H20.2l1.078.829a39.1,39.1,0,0,0,18.571,7.627l.5.083V92.752l-.415-.166A37.814,37.814,0,0,1,24.428,86.783Z'
                  transform='translate(-4.365 -16.23)'
                  fill='#1d4354'
                />
                <path
                  id='Path_6060'
                  data-name='Path 6060'
                  d='M63.183,32.438H55.638v-.083A5.555,5.555,0,0,0,50.084,26.8h-9.12a5.555,5.555,0,0,0-5.555,5.555v.083H27.948A3.68,3.68,0,0,0,24.3,36.085V61.04a3.68,3.68,0,0,0,3.648,3.648H63.183a3.68,3.68,0,0,0,3.648-3.648V36.085A3.68,3.68,0,0,0,63.183,32.438Zm-25.121-.083a2.937,2.937,0,0,1,2.9-2.9h9.12a2.937,2.937,0,0,1,2.9,2.9v.083l-14.923-.083Zm11.607,19.9V48.19h8.374l6.135-4.145V60.957a1.008,1.008,0,0,1-.995.995H27.948a1.008,1.008,0,0,1-.995-.995V44.044l5.223,3.9,8.788.083v4.145h8.705ZM33.088,45.288l-6.135-4.56V36.085a1.008,1.008,0,0,1,.995-.995H63.183a1.008,1.008,0,0,1,.995.995v4.891l-6.964,4.56Zm13.928,2.9v1.326h-3.4V48.19Z'
                  transform='translate(-5.066 -5.991)'
                  fill='#1d4354'
                />
              </svg>
            </div>
          </div>
          <div className='col-lg-12'>
            <div className='row text-left'>
              <div className='col-lg-4 col-md-4 mt-3'>
                <button
                  onClick={() => setTabs(1)}
                  className={` ${
                    tabs == 1
                      ? 'font-clr-green font-weight-bold active-border'
                      : 'font-weight-light darker border-0'
                  } lh-1 my-xxxl-4 pl-2 p10 bg-trans w-100 text-left`}
                >
                  Resume
                </button>
                <button
                  onClick={() => setTabs(2)}
                  className={` ${
                    tabs == 2
                      ? 'font-clr-green font-weight-bold active-border'
                      : 'font-weight-light darker border-0'
                  } lh-1 my-xxxl-4 pl-2 p10 bg-trans w-100 text-left`}
                >
                  Telephone Interview
                </button>
                <button
                  onClick={() => setTabs(3)}
                  className={` ${
                    tabs == 3
                      ? 'font-clr-green font-weight-bold active-border'
                      : 'font-weight-light darker border-0'
                  } lh-1 my-xxxl-4 pl-2 p10 bg-trans w-100 text-left`}
                >
                  Test for Job
                </button>

                <button
                  onClick={() => setTabs(4)}
                  className={` ${
                    tabs == 4
                      ? 'font-clr-green font-weight-bold active-border'
                      : 'font-weight-light darker border-0'
                  } lh-1 my-xxxl-4 pl-2 p10 bg-trans w-100 text-left`}
                >
                  Job Interview
                </button>
                <button
                  onClick={() => setTabs(5)}
                  className={` ${
                    tabs == 5
                      ? 'font-clr-green font-weight-bold active-border'
                      : 'font-weight-light darker border-0'
                  } lh-1 my-xxxl-4 pl-2 p10 bg-trans w-100 text-left`}
                >
                  Employment
                </button>
              </div>
              <div className='col-lg-8 col-md-8 darker p15 mt-3'>
                <p>
                  {tabs == 1
                    ? `In the appropriate section, select the vacancy that interests you
                    and send a resume with a detailed description of your work
                    experience, an indication of technologies and projects (if any).
                    And if you are still a student, be sure to list the courses you
                    attended, indicate participation in contests and olympiads, as
                    well as your course and diploma projects.`
                    : tabs == 2
                    ? ` be sure to list the courses you
                attended, indicate participation in contests and olympiads, as
                well as your course and diploma projects.`
                    : tabs == 3
                    ? `Lorem epsum`
                    : tabs == 4
                    ? `lorem epsum 4`
                    : `lorem epsum 5`}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Joining
