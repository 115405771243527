import React from 'react'
import img from '../../../assets/Group 47.png'
function CaseStudies(props) {
  return (
    <div className='bg-light'>
      <div className='col-lg-10 mx-auto'>
        <div className='row justify-content-center'>
          <div className='col-10 mx-auto pb-3 mb-2 pt--80'>
            <div className='p3 font-clr-green mb-2 upper'>WHO WE SERVE</div>
            <div className='font-clr-blue row '>
              <div className='col-lg-10 col-md-10 col-12 mx-auto'>
                <h1 className='p9 f-21 mb-0'>
                  ELIMINATE THE IT CHALLENGES YOUR BUSINESS
                </h1>
              </div>
              <div className='para-d mt-3 mb-3 p8 lh-15'>
                Our vertical solutions expertise allows your business to
                streamline workflow, and increase productivity. No matter the
                business, Digimark Developers has you covered.
              </div>
            </div>
          </div>

          <div className='col-lg-6 col-md-6 mb-4'>
            <a href=''>
              {' '}
              <div class='card serve-card'>
                <div className='img-container'>
                  <img
                    src={img}
                    height='200px'
                    className='card-img-top no-radius'
                    alt='Snow'
                  />
                  <div class='centered p14-n mx-auto'>Digimark Developers</div>
                </div>
                <div class='card-body text-left pb-0'>
                  <div className=''>
                    <h6 className='font-clr-green text-left w-100 p3'>
                      Cyber Security
                    </h6>
                    <h5 className='font-clr-blue mt-0 mb-2 font-weight-bold mx-auto p10'>
                      Support center for semiconductor provider
                    </h5>
                    <p class='card-text para-d mb-3 pb-3'>
                      Overview As a global player in the telecoms industry,
                      Vodafone’s reputation thrives on their ability ...
                    </p>
                    <div className='d-block align-items-center green-hov-child svg'>
                      <button className='button button--pan  hov-btn-color helvetica font-weight-lighter btn-block'>
                        <span className='d-block lh-1 pt-xxl-1 p16'>
                          DISCOVER HOW WE HELP
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className='col-lg-6 col-md-6 mb-4'>
            <a href=''>
              <div class='card serve-card'>
                <div className='img-container'>
                  <img
                    src={img}
                    height='200px'
                    className='card-img-top no-radius'
                    alt='Snow'
                  />
                  <div class='centered p14-n mx-auto'>Digimark Developers</div>
                </div>
                <div class='card-body text-left pb-0'>
                  <div className=''>
                    <h6 className='font-clr-green p3 text-left w-100'>
                      Cyber Security
                    </h6>
                    <h5 className='font-clr-blue mt-0 mb-2 font-weight-bold mx-auto p10'>
                      Support center for semiconductor provider
                    </h5>
                    <p class='card-text para-d mb-3 pb-3'>
                      Overview As a global player in the telecoms industry,
                      Vodafone’s reputation thrives on their ability ...
                    </p>
                    <div className='d-block align-items-center green-hov-child svg'>
                      <button className='button button--pan  hov-btn-color helvetica font-weight-lighter btn-block'>
                        <span className='d-block lh-1 pt-xxl-1 p16'>
                          DISCOVER HOW WE HELP
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class='col-10 mx-auto pt-4 pb-5 font-clr-blue p8 explore'>
            Find out how companies succeed with us.
            <a
              href='#'
              target='_blank'
              class='h-n-link darker p8 font-weight-bold'
            >
              Read our case studies
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CaseStudies
