import React from "react";
import Header from "../shared/Header";
import CarouselMain from "../small/homesection/CarouselMain";
import Card from "../small/homesection/cards";
import Ourcompany from "../small/homesection/Ourcompany";
import ClientsMarquee from "../small/homesection/ClientsMarquee";
import CaseStudies from "../small/homesection/CaseStudies";
import WhyUs from "../small/homesection/WhyUs";
import Process from "../small/homesection/Process";
import ScrollAnimation from "react-animate-on-scroll";
import ContactUs from "../small/homesection/ContactUs";
import Blogs from "../small/homesection/Blogs";
import Footer from "../shared/Footer";
import Scrolltop from "../shared/Scrolltop";
import StickyHeader from "../shared/StickyHeader";
import Techtext from "../shared/Techtext";
function Home(props) {
  return (
    <div>
      <Scrolltop />
      <Header />
      <StickyHeader />
      <ScrollAnimation
        delay={500}
        animateIn="animate__fadeInLeft"
        animateOnce={true}
      >
        <CarouselMain />
      </ScrollAnimation>
      <Ourcompany />
      <Card />
      <ClientsMarquee />
      <CaseStudies />
      <WhyUs />
      <Techtext text="Explore our company" />
      <Process />
      <ContactUs />
      <Blogs />
      <Footer />
    </div>
  );
}

export default Home;
