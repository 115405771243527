import React, { useState } from 'react'
import ourservice from '../../../assets/ourservice.png'
import whypartner from '../../../assets/whypartner.jpg'
import casestudy from '../../../assets/casestudy.png'
function Ourcompany(props) {
  const [service, setService] = useState(false)
  const [partner, setPartner] = useState(false)
  //   const [casestudy, setCasestudy] = useState(false);
  return (
    <div className='bg-seconday'>
      <div className='container-fluid'>
        <div className='row sans text-white'>
          <div className='col-lg-10 mx-auto pt-5'>
            <div className='row'>
              <div className='col-lg-6 col-md-12 text-left '>
                <h5 className='p3 parrot mb-1 upper'>Our Company</h5>
                <div className='p4 font-weight-bold lh-15'>
                  Robust Web & Mobile digital Solutions for Your Business
                </div>
              </div>
              <div className='col-lg-6 col-md-12 text-left text-white lh-15'>
                Today’s modern business environment requires organizations to
                provide their customers with robust and intelligent web and
                mobile solutions. We understand that successful solutions are
                only possible through collaboration. We listen to our client’s
                ideas and needs, then build a solution framework based on their
                feedback.
              </div>
            </div>
          </div>
          <div className='col-12 col-xl-11 mx-auto pt-5 company-card'>
            <div className='row mx-auto pt-3 mb--5'>
              <div className='col-xl col-md mx-auto mb-4 mb-xl-0'>
                <img
                  src={whypartner}
                  alt=''
                  className='w-100 card-h-resp br-10'
                />
                <div className='row mx-auto justify-content-center'>
                  <div className='bg-white card-d-btn col-10 col-xxl-9 b-3 upcard br-5 py-desktop-3 mx-auto w-fit text-center company-card-title'>
                    <h5 className=' font-clr-green mb-1 font-weight-bold op'>
                      Our Services
                    </h5>
                    <h4 className='mb-1 mt-0 secondary upcardwhite '>
                      How we can help
                    </h4>
                    <div className='parrot  mb-1 afterup'>
                      Learn More
                      <i className='fa fa-arrow-right ml-2'></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xl col-md mx-auto mb-4 mb-xl-0'>
                <img
                  src={whypartner}
                  alt=''
                  className='w-100 card-h-resp br-10'
                />
                <div className='row mx-auto justify-content-center'>
                  <div className='bg-white card-d-btn col-10 col-xxl-9 b-3 upcard br-5 py-desktop-3 mx-auto w-fit text-center company-card-title'>
                    <h5 className=' font-clr-green mb-1 op font-weight-bold'>
                      Our expertise
                    </h5>
                    <h4 className='mb-1 mt-0 secondary upcardwhite '>
                      Why partner with us
                    </h4>
                    <div className='parrot  font-weight-bold mb-1 afterup'>
                      Learn More
                      <i className='fa fa-arrow-right ml-2'></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xl col-md mx-auto mb-4 mb-xl-0'>
                <img
                  src={whypartner}
                  alt=''
                  className='w-100 card-h-resp br-10'
                />
                <div className='row mx-auto justify-content-center'>
                  <div className='bg-white card-d-btn col-10 col-xxl-9 b-3 upcard br-5 py-desktop-3 mx-auto w-fit text-center company-card-title'>
                    <h5 className=' font-clr-green mb-1 op font-weight-bold'>
                      Case Studies
                    </h5>
                    <h4 className='mb-1 mt-0 secondary upcardwhite '>
                      Case studies stories
                    </h4>
                    <div className='parrot  mb-1 afterup font-weight-bold'>
                      Learn More
                      <i className='fa fa-arrow-right ml-2'></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='bg-light-v py-md-5 text-light'>
        <div className='pt-xl-4 pb-xxl-5 pb-4'>
          <div className='py-xl-5'></div>
        </div>
        <div className='col-10 mx-auto mt-5 font-clr-blue p8 py-md-0 pt-md-5 py-5 explore'>
          Stop wasting time and money on technology.
          <a
            href='#'
            target='_blank'
            className='h-n-link darker p8 font-weight-bold'
          >
            Explore our company
          </a>
        </div>
      </div>
    </div>
  )
}

export default Ourcompany
