import React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
function Faqs(props) {
  return (
    <div className='bg-light-v mx-auto row py--80'>
      <div className='col-lg-10 mx-auto'>
        <div className='col-12 p9 f-24 darker pb-xl-4 pt-xl-2'>
          TOP 10 MOST POPULAR FAQS
        </div>
        <div className='row mx-auto'>
          <div className='col-md-6 col-12 mx-auto accordian'>
            <div className='my-3 mb-3 mt-md-4 accordian'>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                >
                  <Typography>
                    <p class='text-left darker P18 py-xl-4 px-2 py-2 mb-0 faq-head'>
                      Lorem ipsum dolor sit amet, consetetur sadipscing?
                    </p>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className='text-left darker font-weight-light border-top pt-2'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className='mb-3 accordian'>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                >
                  <Typography>
                    <p class='text-left darker P18 py-xl-4 px-2 py-2 mb-0 faq-head'>
                      Lorem ipsum dolor sit amet, consetetur sadipscing?
                    </p>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className='text-left darker font-weight-light border-top pt-2'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className='mb-3 accordian'>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                >
                  <Typography>
                    <p class='text-left darker P18 py-xl-4 px-2 py-2 mb-0 faq-head'>
                      Lorem ipsum dolor sit amet, consetetur sadipscing?
                    </p>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className='text-left darker font-weight-light border-top pt-2'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className='mb-3 accordian'>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                >
                  <Typography>
                    <p class='text-left darker P18 py-xl-4 px-2 py-2 mb-0 faq-head'>
                      Lorem ipsum dolor sit amet, consetetur sadipscing?
                    </p>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className='text-left darker font-weight-light border-top pt-2'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          <div className='col-md-6 col-12 mx-auto'>
            <div className='mb-3 mt-md-4 accordian'>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                >
                  <Typography>
                    <p class='text-left darker P18 py-xl-4 px-2 py-2 mb-0 faq-head'>
                      Lorem ipsum dolor sit amet, consetetur sadipscing?
                    </p>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className='text-left darker font-weight-light border-top pt-2'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>{' '}
            <div className='mb-3 accordian'>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                >
                  <Typography>
                    <p class='text-left darker P18 py-xl-4 px-2 py-2 mb-0 faq-head'>
                      Lorem ipsum dolor sit amet, consetetur sadipscing?
                    </p>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className='text-left darker font-weight-light border-top pt-2'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>{' '}
            <div className='mb-3 accordian'>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                >
                  <Typography>
                    <p class='text-left darker P18 py-xl-4 px-2 py-2 mb-0 faq-head'>
                      Lorem ipsum dolor sit amet, consetetur sadipscing?
                    </p>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className='text-left darker font-weight-light border-top pt-2'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>{' '}
            <div className='mb-3 accordian'>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls='panel1a-content'
                  id='panel1a-header'
                >
                  <Typography>
                    <p class='text-left darker P18 py-xl-4 px-2 py-2 mb-0 faq-head'>
                      Lorem ipsum dolor sit amet, consetetur sadipscing?
                    </p>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className='text-left darker font-weight-light border-top pt-2'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
        <div className='row mx-auto mb-2 mt-5'>
          <div className='col-12 mx-auto font-clr-blue p8 explore'>
            Couldn’t find your answer?
            <a
              href='#'
              target='_blank'
              class='h-n-link darker pl-1 p8 font-weight-bold'
            >
              Ask a question
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Faqs
