import React from 'react'
import whatwedo from '../../../assets/About Us Page/Group 3787.svg'
import believe from '../../../assets/About Us Page/Group 3788.svg'
function Whatwedo(props) {
  return (
    <div className='bg-light-v row mx-auto py-xxl-5'>
      <div className='col-lg-10 mx-auto'>
        <div className=''>
          <div className='row mx-auto text-left py-xl-5 align-items-center'>
            <div className='col-md-6 col-lg-6 mb-5'>
              <div className='p4 darker font-weight-bold mb-4'>What We Do</div>
              <div className='para-d p8 font-weight-light linee-h-2 lh-4'>
                DigiMark Developers is a global leader in mobile
                game/application design and development. We create comprehensive
                games and applications for startups, businesses, and enterprises
                across various Android and iOS. Our vision is to constantly
                develop mobile apps and games and grow as a major mobile app and
                game development company.
              </div>
            </div>
            <div className='col-md-6 col-lg-6 mb-5'>
              <img src={whatwedo} width='100%' alt='whatwedo_img' />
            </div>
          </div>
          <div className='row mx-auto text-left py-xl-3 align-items-center'>
            <div className='col-md-6 col-lg-6 mb-5'>
              <img src={believe} width='90%' alt='believe_img' />
            </div>
            <div className='col-md-6 col-lg-6 mb-5'>
              <div className='p4 darker font-weight-bold mb-4'>
                What We Believe
              </div>
              <div className='para-d p8 font-weight-light linee-h-2 lh-4'>
                We believe in providing excellent services that are backed with
                international standards of coding. We blend the perfect plan for
                your project with our deep analysis and market research that
                guarantees an accurate outcome. We also conduct several
                workshops and programs to update our employees and keep support
                in their self-growth.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Whatwedo
