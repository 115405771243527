import React from 'react'
import img from '../../../assets/Group 48.png'
function Blogs(props) {
  return (
    <div className='bg-light-v' id='blogs'>
      <div className='col-lg-10 mx-auto'>
        <div className='row mx-auto justify-content-center'>
          <div className='pt--80'>
            <div class='col-12 col-lg-9 mx-auto'>
              <h6 class='p3 font-clr-green upper text-center w-100 m-0'>
                FORM OUR BLOG
              </h6>
              <h1 class='font-weight-bold font-clr-blue mx-auto col-lg-10 mb-0 p9 f-25'>
                IDEAS THAT DRIVE TOMORROW'S INNOVATION
              </h1>
              <div className='para-d mt-2 mb-2 p8 p-14 lh-15'>
                Our vertical solutions expertise allows your business to
                streamline workflow, and increase productivity. No matter the
                business, Digimark Developers has you covered.
              </div>
            </div>
            <div className='row mx-auto mt-4 pt-xl-2'>
              <div className='col-lg-4 col-md-6 col-12 mx-auto mb-3'>
                <div class='card br-5 border-0 card-bb-animate'>
                  <img
                    class='card-img-top'
                    height='210px'
                    src={img}
                    alt='Card image cap'
                  />
                  <div class='card-body text-left pl-xxl-5 pl-5'>
                    <h6 className='font-clr-green p21 text-left w-100'>
                      MAY 8, 2018
                    </h6>
                    <h5 className='darker p23 font-weight-bold mb-2 mt-0'>
                      Improving lives with technology – HSE lighthouse project
                    </h5>
                    <h6 class='card-text p15 para-d lh-15 pt-xl-2'>
                      The ‘Lighthouse Projects’ are in the clinical disciplines
                      of the chronic diseases Epilepsy the chronic diseases
                      Epilepsy...
                    </h6>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 col-12 mx-auto mb-3'>
                <div class='card br-5 border-0 card-bb-animate'>
                  <img
                    class='card-img-top'
                    height='210px'
                    src={img}
                    alt='Card image cap'
                  />
                  <div class='card-body text-left pl-xxl-5 pl-5'>
                    <h6 className='font-clr-green p21 text-left w-100 m-0'>
                      MAY 8, 2018
                    </h6>
                    <h5 className='darker p23 font-weight-bold mb-2 mt-0'>
                      Improving lives with technology – HSE lighthouse project
                    </h5>
                    <h6 class='card-text p15 para-d lh-15 pt-xl-2'>
                      The ‘Lighthouse Projects’ are in the clinical disciplines
                      of the chronic diseases Epilepsy the chronic diseases
                      Epilepsy...
                    </h6>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 col-12 mx-auto mb-3'>
                <div class='card br-5 border-0 card-bb-animate'>
                  <img
                    class='card-img-top'
                    height='210px'
                    src={img}
                    alt='Card image cap'
                  />
                  <div class='card-body text-left pl-xxl-5 pl-5'>
                    <h6 className='font-clr-green p21 text-left w-100 m-0'>
                      MAY 8, 2018
                    </h6>
                    <h5 className='darker p23 font-weight-bold mb-2 mt-0'>
                      Improving lives with technology – HSE lighthouse project
                    </h5>
                    <h6 class='card-text p15 para-d lh-15 pt-xl-2'>
                      The ‘Lighthouse Projects’ are in the clinical disciplines
                      of the chronic diseases Epilepsy the chronic diseases
                      Epilepsy...
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='col-10 mx-auto pb-5 font-clr-blue pt-5 p8 explore'>
            Insights to help you do what you do better, faster and more
            profitably.
            <a
              href='#'
              target='_blank'
              className='h-n-link  font-weight-bold p8 explore'
            >
              View all article
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Blogs
