import React, { useState } from 'react'
import meet_icon from '../../../assets/noun_Meeting schedule_1613249.svg'
import talk_icon from '../../../assets/noun_talking_4101014.svg'
import tech_icon from '../../../assets/noun_digitalized_2226568.svg'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormHelperText from '@mui/material/FormHelperText'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import client1 from '../../../assets/Ellipse 2.png'
import client2 from '../../../assets/Ellipse 3.png'
import '../../styles/media.css'

function ContactUs(props) {
  const [age, setAge] = React.useState('')
  const [industry, setIndustry] = useState('')
  const [budget, setBudget] = useState('')
  const handleChange = (event) => {
    setAge(event.target.value)
  }
  const handleChangeInd = (event) => {
    setIndustry(event.target.value)
  }
  const handleChangebudget = (event) => {
    setBudget(event.target.value)
  }
  return (
    <div className='bg-contact'>
      <div className='row mx-auto'>
        <div className='col-lg-10 mx-auto'>
          <div className='row mx-auto m-contact mt-lg--16 shadow'>
            <div className='col-lg-5 col-md-6 bg-blue text-white text-center py-5 px-4'>
              <p className='font-clr-green p8 mt-xl-5 pt-xl-1 mb-xl-3 my-2'>
                HOW IT WORKS
              </p>
              <h4 className='font-weight-bold p19 m-0'>
                GET A IT SOLUTIONS QUOTE
              </h4>
              <p className='light-grey mb-0'>
                Please contact our team or complete the form below. A
                representative will contact you shortly.
              </p>
              <div className='d-flex mb-2 pt-4 pl-xxl-5 px-5'>
                <a
                  href='#'
                  target='_blank'
                  className='px-xxl-5 px-xl-4 px-2'
                  rel='noopener noreferrer'
                >
                  <img src={meet_icon} width='62px' alt='' />
                </a>
                <div className='text-left pl-xxl-3'>
                  <h4 className='font-weight-bold p10'>Schedule Meeting</h4>
                  <p className='light-grey w-md-75'>
                    If we’re the right fit, you’ll choose the Digital service
                    agreement that works best for your Business.
                  </p>
                </div>
              </div>
              <div className='d-flex mb-2 pt-4 pl-xxl-5 px-5'>
                <a
                  href='#'
                  target='_blank'
                  className='px-xxl-5 px-xl-4 px-2'
                  rel='noopener noreferrer'
                >
                  <img src={talk_icon} width='62px' alt='' />
                </a>
                <div className='text-left pl-xxl-3'>
                  <h4 className='font-weight-bold p10'>Let’s Talk</h4>
                  <p className='light-grey w-md-75'>
                    We’ll chat about your business, how you use technology, and
                    what you want to get out of Digital.
                  </p>
                </div>
              </div>
              <div className='d-flex mb-2 pt-4 pl-xxl-5 px-5 mb-xl-3 pb-xl-3'>
                <a
                  href='#'
                  target='_blank'
                  className='px-xxl-5 px-xl-4 px-2'
                  rel='noopener noreferrer'
                >
                  <img src={tech_icon} width='62px' alt='' />
                </a>
                <div className='text-left pl-xxl-3'>
                  <h4 className='font-weight-bold p10'>
                    Start Your Digital Experience
                  </h4>
                  <p className='light-grey w-md-75'>
                    Within days, you’ll be experiencing Digital like never
                    before.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-lg-7 contact-form col-md-6 bg-white text-white text-center pt-5 pb-5'>
              <div className='row mx-auto mt-xxl-5 px-xl-5'>
                <div className='col-xl-6 col-12 mx-auto my-2 my-xl-4 pb-xl-2'>
                  <TextField
                    required
                    id='standard-basic'
                    label='Your Name'
                    className='text-dark-bb darker border-bottom w-100'
                    variant='standard'
                  />
                </div>
                <div className='col-xl-6 col-12 mx-auto my-2 my-xl-4 pb-xl-2'>
                  <TextField
                    required
                    id='standard-basic'
                    className='text-dark-bb border-bottom w-100'
                    label='Your Mail'
                    variant='standard'
                  />
                </div>
                <div className='col-xl-6 col-12 mx-auto my-2 my-xl-4 pb-xl-2'>
                  <TextField
                    required
                    id='standard-basic'
                    label='Your Number'
                    className='text-dark-bb border-bottom w-100'
                    variant='standard'
                  />
                </div>
                <div className='col-xl-6 col-12 mx-auto my-2 my-xl-4 pb-xl-2'>
                  <TextField
                    required
                    id='standard-basic'
                    className='text-dark-bb border-bottom w-100'
                    label='Company Name'
                    variant='standard'
                  />
                </div>
                <div className='col-12 mx-auto my-2 my-xl-4 pb-xl-2'>
                  <FormControl
                    className='w-100 border-bottom'
                    variant='standard'
                  >
                    <InputLabel id='demo-simple-select-standard-label'>
                      <span className='text-dark-b'>
                        What is your Digital Need? *
                      </span>
                    </InputLabel>
                    <Select
                      labelId='demo-simple-select-standard-label'
                      id='demo-simple-select-standard'
                      value={age}
                      onChange={handleChange}
                      label='Age'
                      className='text-left'
                    >
                      <MenuItem className='text-left' value=''>
                        <em>None</em>
                      </MenuItem>
                      <MenuItem className='text-left' value={10}>
                        Lorem Epsum
                      </MenuItem>
                      <MenuItem value={20}>Lorem Epsum</MenuItem>
                      <MenuItem value={30}>Lorem Epsum</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className='col-12 mx-auto my-2 my-xl-4 pb-xl-2'>
                  <FormControl
                    className='w-100 border-bottom'
                    variant='standard'
                  >
                    <InputLabel id='demo-simple-select-standard-label'>
                      <span className='text-dark-b'>
                        Your Estimated Budget?*
                      </span>
                    </InputLabel>
                    <Select
                      labelId='demo-simple-select-standard-label'
                      id='demo-simple-select-standard'
                      value={budget}
                      onChange={handleChangebudget}
                      label='Age'
                      className='text-left'
                    >
                      <MenuItem className='text-left' value=''>
                        <em>None</em>
                      </MenuItem>
                      <MenuItem className='text-left' value={10}>
                        Lorem Epsum
                      </MenuItem>
                      <MenuItem value={20}>Lorem Epsum</MenuItem>
                      <MenuItem value={30}>Lorem Epsum</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className='col-12 mx-auto my-2 my-xl-4 pb-xl-2'>
                  <FormControl
                    className='w-100 border-bottom'
                    variant='standard'
                  >
                    <InputLabel id='demo-simple-select-standard-label'>
                      <span className='text-dark-b'>Select your Industry</span>
                    </InputLabel>
                    <Select
                      labelId='demo-simple-select-standard-label'
                      id='demo-simple-select-standard'
                      value={industry}
                      onChange={handleChangeInd}
                      label='Age'
                      className='text-left'
                    >
                      <MenuItem className='text-left' value=''>
                        <em>None</em>
                      </MenuItem>
                      <MenuItem className='text-left' value={10}>
                        Lorem Epsum
                      </MenuItem>
                      <MenuItem value={20}>Lorem Epsum</MenuItem>
                      <MenuItem value={30}>Lorem Epsum</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className='col text-right mt-5'>
                  <div className='d-block align-items-center green-hov-child svg mx-2 mx-xl-3 mt-xxl-5'>
                    <button className='border-0 contact-btn bg-blue bg-blue-hov ml-auto text-white d-flex helvetica'>
                      <span className='light-grey py-xl-1 m-auto'>Submit</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row mx-auto pb-xl-4 pt-xl-5 py-md-5 py-4 text-left mb-xl-3 mt-lg-4'>
            <div className='col-xl-6 col-lg col-md px-xl-0'>
              <div className='d-lg-flex align-items-center align-content-between'>
                <div className='d-lg-block d-flex align-items-center mb-2'>
                  <img
                    src={client1}
                    alt='clientimg'
                    width='60'
                    className='circle-rounded'
                  />
                  <div className='ml-3 ml-lg-0'>
                    <label
                      htmlFor=''
                      className='mb-0 parrot p21 lh-1 d-block mt-2 font-weight-bold'
                    >
                      Jeffrey
                    </label>
                    <div className='text-white p11 mt-2'>
                      <p>Brooklyn, NY</p>
                    </div>
                  </div>
                </div>
                <div className='p20 col-12 col-lg-9 col-xl-9 ml-xxl-5 pr-pl-5 my-4 my-md-0 lh-15'>
                  <p>
                    Being back with NanoSoft just gives me peace of mind knowing
                    that my technology is functioning seamlessly behind the
                    scenes without it interfering with and disrupting our
                    day-to-day operations.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-xl-6 col-lg col-md px-xl-0'>
              <div className='d-lg-flex align-items-center align-content-between'>
                <div className='d-lg-block d-flex align-items-center mb-2'>
                  <img
                    src={client2}
                    alt='clientimg'
                    width='60'
                    className='circle-rounded'
                  />
                  <div className='ml-3 ml-lg-0'>
                    <label
                      htmlFor=''
                      className='mb-0 parrot p21 lh-1 d-block mt-2 font-weight-bold'
                    >
                      Jeffrey
                    </label>
                    <div className='text-white p11 mt-2'>
                      <p>Brooklyn, NY</p>
                    </div>
                  </div>
                </div>
                <div className='p20 col-12 col-lg-9 col-xl-9 ml-xxl-5 pr-pl-5 my-4 my-md-0 lh-15'>
                  <p>
                    Being back with NanoSoft just gives me peace of mind knowing
                    that my technology is functioning seamlessly behind the
                    scenes without it interfering with and disrupting our
                    day-to-day operations.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUs
