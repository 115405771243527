import React from 'react'

function Process(props) {
  return (
    <div className='bg-light-v'>
      <div className='col-lg-10 mx-auto'>
        <div className='row justify-content-center py--80'>
          <div className='col-12 col-lg-10 pb-4'>
            <h6 className='p3 font-clr-green upper text-center w-100'>
              Process We Follow
            </h6>
            <div className='font-clr-blue row mx-auto'>
              <h1 className='font-weight-bold mx-auto p9 p-9 mb-0'>
                WE SIMPLIFY ENGAGEMENT PROCESS
              </h1>
            </div>
          </div>
          <div className='col-lg-12'>
            <div className='row mx-auto'>
              <div className='col-lg-3 col-md-4 card-d bg-light-v text-center p-4 mt-4'>
                <div className='number '>01</div>
                <div class='overlay-box h-icon-auto mb-2  fill mb-0 mr-auto text-left'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='58.97'
                    height='54'
                    viewBox='0 0 58.97 50.891'
                  >
                    <g
                      id='noun_requirement_review_2715253'
                      data-name='noun_requirement review_2715253'
                      transform='translate(-5 -12.998)'
                    >
                      <g
                        id='Group_146'
                        data-name='Group 146'
                        transform='translate(5 12.998)'
                      >
                        <g id='Group_145' data-name='Group 145'>
                          <path
                            id='Path_83'
                            data-name='Path 83'
                            d='M26.306,21.839A1.309,1.309,0,0,0,25.753,21a1.133,1.133,0,0,0-.861-.174l-.5.1a1.105,1.105,0,0,0-.241.081.513.513,0,0,1-.1.033.5.5,0,0,1-.3-.022.5.5,0,0,1-.281-.277,9.317,9.317,0,0,0-2.038-3.061.574.574,0,0,1-.062-.075.5.5,0,0,1-.068-.155c-.006-.023-.013-.046-.008-.039l-.01-.053.01-.124a.539.539,0,0,1,.136-.268l.372-.565a1.344,1.344,0,0,0-.351-1.8,1.136,1.136,0,0,0-.883-.193,1.155,1.155,0,0,0-.755.529l-.278.44a1.316,1.316,0,0,0-.1.192.51.51,0,0,1-.279.275.5.5,0,0,1-.392-.009,8.555,8.555,0,0,0-3.52-.736.507.507,0,0,1-.491-.447l-.115-.611a1.308,1.308,0,0,0-.553-.844,1.124,1.124,0,0,0-.861-.173,1.257,1.257,0,0,0-.926,1.5l.124.6a.508.508,0,0,1-.293.6,8.486,8.486,0,0,0-3.031,2.132.505.505,0,0,1-.662.072l-.381-.265a1.135,1.135,0,0,0-.883-.193,1.156,1.156,0,0,0-.756.529,1.343,1.343,0,0,0,.351,1.8l.393.277a.5.5,0,0,1,.179.6,9.471,9.471,0,0,0-.656,3.9.5.5,0,0,1-.4.515l-.337.069a1.257,1.257,0,0,0-.926,1.5,1.305,1.305,0,0,0,.551.842,1.134,1.134,0,0,0,.863.175l.411-.084a.5.5,0,0,1,.567.3,9.289,9.289,0,0,0,2.229,3.251.5.5,0,0,1,.081.636l-.2.321a1.344,1.344,0,0,0,.35,1.8,1.129,1.129,0,0,0,.883.193,1.157,1.157,0,0,0,.756-.529l.286-.454a.5.5,0,0,1,.609-.187,8.387,8.387,0,0,0,2.208.552c.2.023.4.045.591.054h0c.308.012.585.011.854-.005a.53.53,0,0,1,.086,0l.088.019a.517.517,0,0,1,.35.413l.1.5a1.3,1.3,0,0,0,.553.844,1.114,1.114,0,0,0,.862.174,1.256,1.256,0,0,0,.926-1.5l-.1-.521c-.01-.047-.021-.09-.033-.134a.5.5,0,0,1,.266-.6,8.5,8.5,0,0,0,.809-.442,8.636,8.636,0,0,0,2.057-1.779.514.514,0,0,1,.325-.175h.045a.454.454,0,0,1,.344.118,1.008,1.008,0,0,0,.1.083l.424.3a1.133,1.133,0,0,0,.883.193,1.156,1.156,0,0,0,.756-.529,1.343,1.343,0,0,0-.351-1.8l-.421-.3a1.183,1.183,0,0,0-.206-.116.506.506,0,0,1-.27-.63,9.5,9.5,0,0,0,.542-3.67.5.5,0,0,1,.49-.528,1.141,1.141,0,0,0,.2-.021l.493-.1A1.258,1.258,0,0,0,26.306,21.839Zm-9.656,7.4a4.929,4.929,0,0,1-3.729-.734,5.116,5.116,0,0,1,1.763-9.242A4.907,4.907,0,0,1,18.411,20a5.116,5.116,0,0,1-1.762,9.242Z'
                            transform='translate(-5 -12.998)'
                            fill='#253858'
                          />
                          <path
                            id='Path_84'
                            data-name='Path 84'
                            d='M42.595,23.718c.039.064.081.125.119.191q.166.283.316.577l.086-.018a2.631,2.631,0,0,1,2,.4,2.8,2.8,0,0,1,1.2,1.81,2.773,2.773,0,0,1-2.072,3.267c0,.114-.014.227-.021.341H82.5V23.334H42.353l.013.02C42.445,23.473,42.52,23.6,42.595,23.718ZM77.2,26.294a1.008,1.008,0,0,1,1.008-1.008h.371a1.008,1.008,0,0,1,1.008,1.008v.39a1.008,1.008,0,0,1-1.008,1.008h-.371A1.008,1.008,0,0,1,77.2,26.684Zm-5.333,0a1.008,1.008,0,0,1,1.008-1.008h.37a1.008,1.008,0,0,1,1.008,1.008v.39a1.008,1.008,0,0,1-1.008,1.008h-.37a1.008,1.008,0,0,1-1.008-1.008Zm-5.333,0a1.008,1.008,0,0,1,1.008-1.008h.37a1.008,1.008,0,0,1,1.008,1.008v.39a1.008,1.008,0,0,1-1.008,1.008h-.37a1.008,1.008,0,0,1-1.008-1.008Z'
                            transform='translate(-23.527 -18.124)'
                            fill='#8d8ea3'
                          />
                          <path
                            id='Path_85'
                            data-name='Path 85'
                            d='M64.258,94.068a2.187,2.187,0,0,0,.133-.188l.026-.044a2.251,2.251,0,0,0,.181-.379c.009-.024.019-.046.027-.071.02-.061.037-.123.051-.186s.023-.108.031-.162.019-.116.024-.175a2.224,2.224,0,0,0,.005-.284c0-.035,0-.071-.008-.105a2.292,2.292,0,0,0-.056-.346A1.988,1.988,0,0,0,62.7,90.589a1.907,1.907,0,0,0-.39.056c-1.342.277-2.668.549-3.99.819l-2.8.572q-2.786.568-5.572,1.139a1.006,1.006,0,0,1-.4.081l-.659.136a.978.978,0,0,1-.3.016l-4.444-.415c-.036,0-.072-.009-.107-.016l-7.7-1.563a1.008,1.008,0,1,1,.4-1.976l7.644,1.552,4.24.4.56-.115,0,0a2.348,2.348,0,0,0,.53-.584c.015-.024.03-.048.043-.073s.038-.073.055-.11.034-.082.049-.124.023-.061.031-.093a1.362,1.362,0,0,0,.047-.244A2.209,2.209,0,0,0,48.072,87.9c-5.134-1.393-10.2-2.774-15.291-4.173l-.161.053q-2.259.755-4.512,1.5-3.46,1.154-6.917,2.3l-.007,0c-.922.306-1.848.617-2.769.922L18.2,96.665,26.082,93.9a1.011,1.011,0,0,1,.67,0l10.633,3.773a1,1,0,0,1,.122.052,13.821,13.821,0,0,0,1.981.811,14.62,14.62,0,0,0,1.885.425q11.046-2.13,22.072-4.28A1.99,1.99,0,0,0,64.258,94.068Z'
                            transform='translate(-11.545 -48.077)'
                            fill='#253858'
                          />
                          <path
                            id='Path_86'
                            data-name='Path 86'
                            d='M20.816,34.807a3.406,3.406,0,0,0,2.588.511,3.6,3.6,0,0,0,1.225-6.5,3.4,3.4,0,0,0-2.588-.51,3.6,3.6,0,0,0-1.225,6.5Z'
                            transform='translate(-12.057 -20.556)'
                            fill='#8d8ea3'
                          />
                          <path
                            id='Path_87'
                            data-name='Path 87'
                            d='M35.552,41.12c-.019.075-.034.15-.055.224l.1.071a2.874,2.874,0,0,1,.759,3.842,2.648,2.648,0,0,1-3.637.864,10.007,10.007,0,0,1-2.03,1.552,2.771,2.771,0,0,1-2.115,3.244,2.642,2.642,0,0,1-.524.053h0a2.619,2.619,0,0,1-.461-.045c-.044-.008-.087-.019-.13-.029a2.649,2.649,0,0,1-.336-.1c-.043-.016-.085-.031-.127-.049a2.759,2.759,0,0,1-.421-.225,2.811,2.811,0,0,1-.36-.287c-.037-.035-.069-.075-.1-.112a2.757,2.757,0,0,1-.2-.227c-.036-.047-.067-.1-.1-.149a2.767,2.767,0,0,1-.145-.236c-.03-.056-.054-.114-.081-.172s-.052-.1-.073-.156V64.1q4.126-1.373,8.257-2.75l2.3-.765a1,1,0,0,1,.587-.016C41.831,62,47,63.4,52.18,64.81a4.2,4.2,0,0,1,3.4,4.283,3.337,3.337,0,0,1-.081.5l3.289-.671q3.348-.682,6.7-1.37a3.7,3.7,0,0,1,.772-.11,3.967,3.967,0,0,1,3.058,1.317,4.106,4.106,0,0,1,.939,1.737,4.3,4.3,0,0,1,.089.472c.006.043.01.086.014.129q.02.185.023.37c0,.039,0,.078,0,.117a4.258,4.258,0,0,1-.114.939c0,.017-.006.035-.01.052h3.884V41.12Zm8.363,13.486-2.78,2.927a1.007,1.007,0,0,1-1.462,0L38.219,56a1.008,1.008,0,0,1,1.462-1.388l.724.762,2.049-2.157a1.008,1.008,0,1,1,1.461,1.388Zm0-8-2.78,2.927a1.007,1.007,0,0,1-1.462,0l-1.455-1.532a1.008,1.008,0,0,1,1.462-1.388l.724.762,2.049-2.157a1.008,1.008,0,1,1,1.461,1.388Zm19.132,10.3H48.615a1.008,1.008,0,0,1,0-2.016H63.046a1.008,1.008,0,0,1,0,2.016Zm0-8.52H48.615a1.008,1.008,0,0,1,0-2.016H63.046a1.008,1.008,0,0,1,0,2.016Z'
                            transform='translate(-15.172 -26.946)'
                            fill='#8d8ea3'
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                <h5 className='font-weight-bold text-left relative p17'>
                  Requirement Gathering
                </h5>
                <p className='my-2 align-items-center lh-2 text-left relative p18'>
                  Requirement Gathering is the first and very important part of
                  our mobile app development process.
                </p>
              </div>
              <div className='col-lg-3 col-md-4 card-d bg-light-v text-center p-4 mt-4'>
                <div className='number '>02</div>
                <div class='overlay-box h-icon-auto mb-2 fill mb-0 mr-auto text-left'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='56.364'
                    height='54'
                    viewBox='0 0 56.364 54.109'
                  >
                    <g
                      id='noun_UX_UI_Design_371721'
                      data-name='noun_UX UI Design_371721'
                      transform='translate(-7 -9)'
                    >
                      <g
                        id='Group_148'
                        data-name='Group 148'
                        transform='translate(7 9)'
                      >
                        <g id='Group_147' data-name='Group 147'>
                          <path
                            id='Path_88'
                            data-name='Path 88'
                            d='M39,33.365l2.538-2.536a1.131,1.131,0,0,1,.8-.329,1.111,1.111,0,0,1,.172.014,1.127,1.127,0,0,1,.827.6l2.72,5.242.351-.18-2.6-5.011a1.126,1.126,0,0,1,1-1.646h3.126L39,21.314Z'
                            transform='translate(-2.927 -7.433)'
                            fill='#8d8ea3'
                          />
                          <rect
                            id='Rectangle_71'
                            data-name='Rectangle 71'
                            width='9'
                            height='2'
                            transform='translate(9.182 27.055)'
                            fill='#8d8ea3'
                          />
                          <path
                            id='Path_89'
                            data-name='Path 89'
                            d='M62.237,9H8.127A1.128,1.128,0,0,0,7,10.127V48.455a1.127,1.127,0,0,0,1.127,1.127H34.055V60.855H23.909a1.127,1.127,0,1,0,0,2.255H46.455a1.127,1.127,0,1,0,0-2.255H36.309V49.582H62.237a1.126,1.126,0,0,0,1.127-1.127V10.127A1.127,1.127,0,0,0,62.237,9ZM27.291,39.437a1.127,1.127,0,0,1-1.127,1.127H14.891a1.127,1.127,0,0,1-1.127-1.127V34.927A1.128,1.128,0,0,1,14.891,33.8H26.164a1.128,1.128,0,0,1,1.127,1.127ZM26.164,29.291H14.891a1.127,1.127,0,0,1,0-2.255H26.164a1.127,1.127,0,1,1,0,2.255Zm9.018-4.509H14.891a1.127,1.127,0,0,1,0-2.255H35.182a1.127,1.127,0,0,1,0,2.255Zm0-4.509H14.891a1.127,1.127,0,1,1,0-2.255H35.182a1.127,1.127,0,0,1,0,2.255ZM55.954,32.615a1.127,1.127,0,0,1-1.051.718H50.735L53,37.7a1.128,1.128,0,0,1-.482,1.52l-2.353,1.217a1.128,1.128,0,0,1-1.52-.481l-2.53-4.877-3.373,3.369a1.127,1.127,0,0,1-1.924-.8V20.317a1.127,1.127,0,0,1,1.889-.831L55.666,31.376A1.127,1.127,0,0,1,55.954,32.615Z'
                            transform='translate(-7 -9)'
                            fill='#8d8ea3'
                          />
                        </g>
                      </g>
                    </g>
                  </svg>{' '}
                </div>
                <h5 className='font-weight-bold text-left relative p17'>
                  UI/UX Design
                </h5>
                <p className='my-2 align-items-center lh-2 text-left relative p18'>
                  Requirement Gathering is the first and very important part of
                  our mobile app development process.
                </p>
              </div>
              <div className='col-lg-3 col-md-4 card-d bg-light-v text-center p-4 mt-4'>
                <div className='number '>03</div>
                <div class='overlay-box h-icon-auto mb-2  fill mb-0 mr-auto text-left'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='58.926'
                    height='54'
                    viewBox='0 0 58.926 42.671'
                  >
                    <g
                      id='noun_prototype_3950219'
                      transform='translate(-3 -11)'
                    >
                      <g
                        id='Group_149'
                        data-name='Group 149'
                        transform='translate(3 11)'
                      >
                        <path
                          id='Path_90'
                          data-name='Path 90'
                          d='M15.762,28.271l4.509-3.758v7.515ZM8.08,24.514v7.515l4.509-3.758Zm10.6-1.322H9.667l4.509,3.758Zm6.667-10.16V17.1H3V13.032A2.034,2.034,0,0,1,5.032,11H23.319a2.034,2.034,0,0,1,2.032,2.032ZM12.144,14.048a1.016,1.016,0,0,0-1.016-1.016H7.064a1.016,1.016,0,1,0,0,2.032h4.064A1.016,1.016,0,0,0,12.144,14.048Zm10.16,0a1.016,1.016,0,1,0-1.016,1.016A1.016,1.016,0,0,0,22.3,14.048ZM3,19.128H25.351V51.639a2.034,2.034,0,0,1-2.032,2.032H5.032A2.034,2.034,0,0,1,3,51.639Zm3.048,15.24a1.016,1.016,0,0,0,1.016,1.016H21.287A1.016,1.016,0,0,0,22.3,34.367V22.176a1.016,1.016,0,0,0-1.016-1.016H7.064a1.016,1.016,0,0,0-1.016,1.016Zm0,5.08a1.016,1.016,0,0,0,1.016,1.016h6.1a1.016,1.016,0,0,0,0-2.032h-6.1A1.016,1.016,0,0,0,6.048,39.447Zm0,4.064a1.016,1.016,0,0,0,1.016,1.016H18.24a1.016,1.016,0,1,0,0-2.032H7.064A1.016,1.016,0,0,0,6.048,43.511Zm3.619-10.16h9.018l-4.509-3.758Zm34.988-8.837v7.515l4.509-3.758Zm12.192,7.515V24.514l-4.509,3.758ZM55.26,23.192H46.242l4.509,3.758Zm6.667-10.16V17.1H39.575V13.032A2.034,2.034,0,0,1,41.607,11H59.894a2.034,2.034,0,0,1,2.032,2.032ZM48.719,14.048A1.016,1.016,0,0,0,47.7,13.032H43.639a1.016,1.016,0,1,0,0,2.032H47.7A1.016,1.016,0,0,0,48.719,14.048Zm10.16,0a1.016,1.016,0,1,0-1.016,1.016A1.016,1.016,0,0,0,58.878,14.048Zm-19.3,5.08H61.926V51.639a2.034,2.034,0,0,1-2.032,2.032H41.607a2.034,2.034,0,0,1-2.032-2.032Zm3.048,15.24a1.016,1.016,0,0,0,1.016,1.016H57.862a1.016,1.016,0,0,0,1.016-1.016V22.176a1.016,1.016,0,0,0-1.016-1.016H43.639a1.016,1.016,0,0,0-1.016,1.016Zm0,5.08a1.016,1.016,0,0,0,1.016,1.016h6.1a1.016,1.016,0,1,0,0-2.032h-6.1A1.016,1.016,0,0,0,42.623,39.447Zm0,4.064a1.016,1.016,0,0,0,1.016,1.016H54.814a1.016,1.016,0,1,0,0-2.032H43.639A1.016,1.016,0,0,0,42.623,43.511Zm3.619-10.16H55.26l-4.509-3.758Zm-9.715-2.032H28.4a1.016,1.016,0,1,0,0,2.032h8.128a1.016,1.016,0,0,0,0-2.032Z'
                          transform='translate(-3 -11)'
                          fill='#253858'
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <h5 className='font-weight-bold text-left relative p17'>
                  Prototype
                </h5>
                <p className='my-2 align-items-center lh-2 text-left relative p18'>
                  Requirement Gathering is the first and very important part of
                  our mobile app development process.
                </p>
              </div>
              <div className='col-lg-3 col-md-4 card-d bg-light-v text-center p-4 mt-4'>
                <div className='number '>04</div>
                <div class='overlay-box h-icon-auto mb-2  fill mb-0 mr-auto text-left'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='57.36'
                    height='54'
                    viewBox='0 0 57.36 59.498'
                  >
                    <g
                      id='noun_software_development_1864933_1_'
                      data-name='noun_software development_1864933 (1)'
                      transform='translate(-2.2 -1)'
                    >
                      <g
                        id='Group_156'
                        data-name='Group 156'
                        transform='translate(2.2 1)'
                      >
                        <g
                          id='Group_150'
                          data-name='Group 150'
                          transform='translate(4.276 18.686)'
                        >
                          <path
                            id='Path_100'
                            data-name='Path 100'
                            d='M6.8,21.1V46.666H48.82V34.115H43.243a3.432,3.432,0,0,1-3.44-3.44V21.1Zm15.618,8.367L17.026,33.65l5.02,4.555a.73.73,0,0,1,0,1.023c-.093.186-.372.186-.558.186a.71.71,0,0,1-.465-.186l-5.578-5.113a.447.447,0,0,1-.186-.558A.8.8,0,0,1,15.539,33l6.043-4.741a.621.621,0,0,1,.93.093A.817.817,0,0,1,22.418,29.467ZM30.6,27.236,26.23,40.995a.7.7,0,0,1-.651.465h-.186a.707.707,0,0,1-.465-.837L29.3,26.771a.665.665,0,0,1,.837-.465A.694.694,0,0,1,30.6,27.236Zm9.482,5.857a.562.562,0,0,1,.279.558,1.321,1.321,0,0,1-.186.558L34.6,39.321c-.186.093-.279.186-.465.186a.71.71,0,0,1-.465-.186.73.73,0,0,1,0-1.023l5.02-4.555L33.3,29.56a.729.729,0,0,1-.093-1.023.628.628,0,0,1,.93-.093Z'
                            transform='translate(-6.8 -21.1)'
                            fill='#253858'
                          />
                        </g>
                        <g
                          id='Group_151'
                          data-name='Group 151'
                          transform='translate(0 14.131)'
                        >
                          <path
                            id='Path_101'
                            data-name='Path 101'
                            d='M49.8,46.972a.733.733,0,0,1-.744.744H5.733a.733.733,0,0,1-.744-.744V20.012a.733.733,0,0,1,.744-.744H39.386V16.2H2.2V50.783H52.773V33.771H49.891v13.2Z'
                            transform='translate(-2.2 -16.2)'
                            fill='#253858'
                          />
                        </g>
                        <g
                          id='Group_152'
                          data-name='Group 152'
                          transform='translate(6.786 50.109)'
                        >
                          <path
                            id='Path_102'
                            data-name='Path 102'
                            d='M45.664,62.9H39.9l-2.51-8H18.518l-2.51,8H10.244a.718.718,0,0,0-.744.651.733.733,0,0,0,.744.744h35.42a.733.733,0,0,0,.744-.744A.718.718,0,0,0,45.664,62.9Z'
                            transform='translate(-9.5 -54.9)'
                            fill='#253858'
                          />
                        </g>
                        <g
                          id='Group_153'
                          data-name='Group 153'
                          transform='translate(38.581)'
                        >
                          <path
                            id='Path_103'
                            data-name='Path 103'
                            d='M60.434,1H45.745A2.063,2.063,0,0,0,43.7,3.045V6.113H62.386V3.045A1.985,1.985,0,0,0,60.434,1ZM55.507,4.254H50.672a.744.744,0,0,1,0-1.487h4.834a.733.733,0,0,1,.744.744A.8.8,0,0,1,55.507,4.254Z'
                            transform='translate(-43.7 -1)'
                            fill='#253858'
                          />
                        </g>
                        <g
                          id='Group_154'
                          data-name='Group 154'
                          transform='translate(38.674 6.415)'
                        >
                          <rect
                            id='Rectangle_72'
                            data-name='Rectangle 72'
                            width='18.686'
                            height='54'
                            fill='#253858'
                          />
                        </g>
                        <g
                          id='Group_155'
                          data-name='Group 155'
                          transform='translate(38.674 25.287)'
                        >
                          <path
                            id='Path_104'
                            data-name='Path 104'
                            d='M43.8,31.268a2.063,2.063,0,0,0,2.045,2.045h14.6a2.063,2.063,0,0,0,2.045-2.045V28.2H43.8Zm9.3-1.58A1.116,1.116,0,1,1,51.981,30.8,1.13,1.13,0,0,1,53.1,29.687Z'
                            transform='translate(-43.8 -28.2)'
                            fill='#253858'
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                <h5 className='font-weight-bold text-left relative p17'>
                  Software Development
                </h5>
                <p className='my-2 align-items-center lh-2 text-left relative p18'>
                  Requirement Gathering is the first and very important part of
                  our mobile app development process.
                </p>
              </div>
              <div className='col-lg-3 col-md-4 card-d bg-light-v text-center p-4 mt-4'>
                <div className='number '>05</div>
                <div class='overlay-box h-icon-auto mb-2  fill mb-0 mr-auto text-left'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='38.061'
                    height='54'
                    viewBox='0 0 38.061 50.745'
                  >
                    <g
                      id='noun_quality_assurance_process_4244084'
                      data-name='noun_quality assurance process_4244084'
                      transform='translate(-9 -2)'
                    >
                      <path
                        id='Path_92'
                        data-name='Path 92'
                        d='M46.988,19.4a.263.263,0,0,0-.168-.2L44.5,18.273a.912.912,0,0,1-.551-.653A16.147,16.147,0,0,0,41.7,12.183a.906.906,0,0,1-.072-.849l.982-2.3a.247.247,0,0,0-.017-.253c-.305-.371-.655-.755-1.108-1.219-.447-.436-.831-.786-1.192-1.087a.251.251,0,0,0-.262-.026l-2.3.99a.906.906,0,0,1-.848-.073,16.147,16.147,0,0,0-5.437-2.255.912.912,0,0,1-.653-.551l-.932-2.321a.262.262,0,0,0-.195-.165,18.318,18.318,0,0,0-3.246,0,.266.266,0,0,0-.21.169l-.929,2.317a.906.906,0,0,1-.652.551,16.125,16.125,0,0,0-5.437,2.255.906.906,0,0,1-.853.073l-2.3-.981a.244.244,0,0,0-.251.016c-.372.307-.757.657-1.22,1.108-.436.448-.785.831-1.087,1.192a.251.251,0,0,0-.026.263l.986,2.3a.906.906,0,0,1-.073.848,16.148,16.148,0,0,0-2.256,5.437.906.906,0,0,1-.55.653L9.237,19.2a.264.264,0,0,0-.165.2C9.024,19.893,9,20.422,9,21.03s.024,1.137.072,1.618a.272.272,0,0,0,.169.209l2.319.926a.906.906,0,0,1,.55.653,16.148,16.148,0,0,0,2.256,5.437.906.906,0,0,1,.072.852l-.982,2.3a.249.249,0,0,0,.016.252c.308.372.657.756,1.109,1.219.447.436.831.786,1.192,1.087a.244.244,0,0,0,.261.026l2.3-.99a.908.908,0,0,1,.849.073,16.125,16.125,0,0,0,5.437,2.255.906.906,0,0,1,.652.551l.928,2.322a.263.263,0,0,0,.2.165,16.1,16.1,0,0,0,1.623.072h.011a16.006,16.006,0,0,0,1.612-.072.265.265,0,0,0,.209-.169L30.78,37.5a.912.912,0,0,1,.653-.551A16.147,16.147,0,0,0,36.871,34.7a.906.906,0,0,1,.855-.073l2.3.982a.252.252,0,0,0,.253-.017c.371-.305.755-.655,1.219-1.108.436-.447.786-.831,1.087-1.192a.251.251,0,0,0,.026-.262l-.99-2.3a.906.906,0,0,1,.073-.848,16.146,16.146,0,0,0,2.255-5.437.912.912,0,0,1,.551-.653l2.322-.928a.262.262,0,0,0,.165-.2,15.938,15.938,0,0,0,.072-1.632,15.844,15.844,0,0,0-.072-1.618ZM28.03,35.529a14.5,14.5,0,1,1,14.5-14.5A14.5,14.5,0,0,1,28.03,35.529Z'
                        fill='#8d8ea3'
                      />
                      <path
                        id='Path_93'
                        data-name='Path 93'
                        d='M41.6,40.889c-.059-.02-.121-.024-.181-.05l-1.863-.8a17.9,17.9,0,0,1-5.157,2.14l-.751,1.88a2.084,2.084,0,0,1-1.722,1.3c-.13.014-.272.019-.406.029l4.691,10.322a.988.988,0,0,0,1.607.314l2.213-2.2a2.195,2.195,0,0,1,1.541-.634h3.888a.98.98,0,0,0,.906-1.359Z'
                        transform='translate(-2.113 -3.569)'
                        fill='#8d8ea3'
                      />
                      <path
                        id='Path_94'
                        data-name='Path 94'
                        d='M28.687,9A12.687,12.687,0,1,0,41.373,21.687,12.687,12.687,0,0,0,28.687,9Zm7.359,10.984-9.062,9.062a2.745,2.745,0,0,1-3.845,0l-1.812-1.812a2.719,2.719,0,0,1,0-3.844,2.783,2.783,0,0,1,3.735-.107L32.2,16.14a2.718,2.718,0,1,1,3.844,3.844Z'
                        transform='translate(-0.657 -0.657)'
                        fill='#1d4354'
                      />
                      <path
                        id='Path_95'
                        data-name='Path 95'
                        d='M34.14,18.266l-7.781,7.781a.906.906,0,0,1-1.281,0l-.531-.531A.906.906,0,1,0,23.265,26.8l1.812,1.812a.925.925,0,0,0,1.281,0l9.062-9.062a.906.906,0,1,0-1.281-1.281Z'
                        transform='translate(-1.313 -1.501)'
                        fill='#1d4354'
                      />
                      <path
                        id='Path_96'
                        data-name='Path 96'
                        d='M24.8,44.067l-.752-1.884a17.908,17.908,0,0,1-5.159-2.14l-1.864.8c-.062.026-.125.032-.188.052L12.079,51.837a.955.955,0,0,0,.091.911.968.968,0,0,0,.816.44h3.888a2.2,2.2,0,0,1,1.548.634l2.208,2.2a.986.986,0,0,0,1.6-.3L26.925,45.39c-.139-.01-.283-.016-.416-.03A2.078,2.078,0,0,1,24.8,44.067Z'
                        transform='translate(-0.282 -3.569)'
                        fill='#8d8ea3'
                      />
                    </g>
                  </svg>
                </div>
                <h5 className='font-weight-bold text-left relative p17'>
                  Quality Assurance
                </h5>
                <p className='my-2 align-items-center lh-2 text-left relative p18'>
                  Requirement Gathering is the first and very important part of
                  our mobile app development process.
                </p>
              </div>
              <div className='col-lg-3 col-md-4 card-d bg-light-v text-center p-4 mt-4'>
                <div className='number '>06</div>
                <div class='overlay-box h-icon-auto mb-2  fill mb-0 mr-auto text-left'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='59.162'
                    height='54'
                    viewBox='0 0 59.162 40.396'
                  >
                    <g
                      id='noun_Deployment_1909175'
                      transform='translate(-0.416 -20.582)'
                    >
                      <g id='Group' transform='translate(0.416 20.582)'>
                        <g id='Group-2' data-name='Group'>
                          <path
                            id='Path'
                            d='M106.332,63.262H99.666a1.163,1.163,0,0,1,0-2.326h6.666a1.163,1.163,0,0,1,0,2.326Z'
                            transform='translate(-52.87 -42.162)'
                            fill='#253858'
                          />
                          <path
                            id='Compound_Path'
                            data-name='Compound Path'
                            d='M116.649,62.94a3.315,3.315,0,1,1,3.315-3.315A3.315,3.315,0,0,1,116.649,62.94Z'
                            transform='translate(-60.801 -39.689)'
                            fill='#8d8ea3'
                          />
                          <path
                            id='Path-2'
                            data-name='Path'
                            d='M93.032,35.874a1.163,1.163,0,0,1-.822-1.985l4.714-4.713a1.163,1.163,0,0,1,1.645,1.645l-4.714,4.713A1.159,1.159,0,0,1,93.032,35.874Z'
                            transform='translate(-49.322 -24.995)'
                            fill='#253858'
                          />
                          <path
                            id='Compound_Path-2'
                            data-name='Compound Path'
                            d='M104.316,27.21a3.314,3.314,0,1,1,2.344-5.657h0a3.313,3.313,0,0,1-2.344,5.657Z'
                            transform='translate(-54.207 -20.582)'
                            fill='#8d8ea3'
                          />
                          <path
                            id='Path-3'
                            data-name='Path'
                            d='M98.874,91.074a1.159,1.159,0,0,1-.822-.341L93.338,86.02a1.163,1.163,0,1,1,1.645-1.645L99.7,89.089a1.163,1.163,0,0,1-.822,1.985Z'
                            transform='translate(-49.926 -54.515)'
                            fill='#253858'
                          />
                          <path
                            id='Compound_Path-3'
                            data-name='Compound Path'
                            d='M105.449,99.8a3.313,3.313,0,1,1,2.344-.969h0A3.3,3.3,0,0,1,105.449,99.8Z'
                            transform='translate(-54.814 -59.4)'
                            fill='#8d8ea3'
                          />
                          <path
                            id='Path-4'
                            data-name='Path'
                            d='M18,63.262H11.332a1.163,1.163,0,0,1,0-2.326H18a1.163,1.163,0,0,1,0,2.326Z'
                            transform='translate(-5.632 -42.162)'
                            fill='#253858'
                          />
                          <path
                            id='Compound_Path-4'
                            data-name='Compound Path'
                            d='M3.731,62.94a3.315,3.315,0,1,1,3.315-3.315A3.315,3.315,0,0,1,3.731,62.94Z'
                            transform='translate(-0.416 -39.689)'
                            fill='#8d8ea3'
                          />
                          <path
                            id='Path-5'
                            data-name='Path'
                            d='M26.877,35.874a1.159,1.159,0,0,1-.822-.341L21.342,30.82a1.163,1.163,0,0,1,1.645-1.645L27.7,33.888a1.163,1.163,0,0,1-.822,1.985Z'
                            transform='translate(-11.424 -24.995)'
                            fill='#253858'
                          />
                          <path
                            id='Compound_Path-5'
                            data-name='Compound Path'
                            d='M16.067,27.212a3.313,3.313,0,1,1,2.344-.97A3.313,3.313,0,0,1,16.067,27.212Z'
                            transform='translate(-7.015 -20.583)'
                            fill='#8d8ea3'
                          />
                          <path
                            id='Path-6'
                            data-name='Path'
                            d='M21.035,91.074a1.163,1.163,0,0,1-.822-1.985l4.714-4.713A1.163,1.163,0,0,1,26.57,86.02l-4.714,4.713A1.159,1.159,0,0,1,21.035,91.074Z'
                            transform='translate(-10.82 -54.515)'
                            fill='#253858'
                          />
                          <path
                            id='Compound_Path-6'
                            data-name='Compound Path'
                            d='M14.939,99.8a3.313,3.313,0,1,1,2.344-.969A3.313,3.313,0,0,1,14.939,99.8Z'
                            transform='translate(-6.411 -59.4)'
                            fill='#8d8ea3'
                          />
                        </g>
                        <path
                          id='Compound_Path-7'
                          data-name='Compound Path'
                          d='M43.31,25.31a18,18,0,1,0,18,18,18,18,0,0,0-18-18Zm8.567,23.727L44.36,52.832a1.807,1.807,0,0,1-2.555,0l-7.517-3.794a1.807,1.807,0,0,1,0-2.555l1.161-.73,5.95,3a2.5,2.5,0,0,0,3.367,0l5.95-3,1.161.73A1.807,1.807,0,0,1,51.877,49.037Zm0-4.652L44.36,48.179a1.807,1.807,0,0,1-2.555,0l-7.517-3.794a1.807,1.807,0,0,1,0-2.555l1.161-.73,5.95,3a2.5,2.5,0,0,0,3.367,0l5.95-3,1.161.73A1.807,1.807,0,0,1,51.877,44.385Zm0-4.652L44.36,43.527a1.807,1.807,0,0,1-2.555,0l-7.517-3.794a1.807,1.807,0,0,1,0-2.555L41.8,33.848a1.807,1.807,0,0,1,2.555,0l7.517,3.329A1.807,1.807,0,0,1,51.877,39.733Z'
                          transform='translate(-13.729 -23.111)'
                          fill='#1d4354'
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <h5 className='font-weight-bold text-left relative p17'>
                  Deployment
                </h5>
                <p className='my-2 align-items-center lh-2 text-left relative p18'>
                  Requirement Gathering is the first and very important part of
                  our mobile app development process.
                </p>
              </div>
              <div className='col-lg-3 col-md-4 card-d bg-light-v text-center p-4 mt-4'>
                <div className='number '>07</div>
                <div class='overlay-box h-icon-auto mb-2 fill mb-0 mr-auto text-left'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='57.263'
                    height='54'
                    viewBox='0 0 57.263 42.292'
                  >
                    <g
                      id='noun_technical_support_3611668'
                      data-name='noun_technical support_3611668'
                      transform='translate(-5.633 -25.889)'
                    >
                      <path
                        id='Path_97'
                        data-name='Path 97'
                        d='M59,49.125l-.72-.212a24.035,24.035,0,0,0-48.028,0l-.72.212a5.484,5.484,0,0,0-3.666,6.822l1.828,6.088A2.309,2.309,0,0,0,9.9,63.68a2.175,2.175,0,0,0,.628-.091l.188-.048c3.569-.914,5.448-1.414,5.745-1.53l.7-.277-2.031-8.792a19.371,19.371,0,1,1,38.266,0l-2.032,8.792.7.278c.3.115,2.175.616,5.744,1.53l.172.044a2.3,2.3,0,0,0,2.851-1.548l1.829-6.089A5.485,5.485,0,0,0,59,49.125Z'
                        transform='translate(0)'
                        fill='#1d4354'
                      />
                      <path
                        id='Path_98'
                        data-name='Path 98'
                        d='M46.383,69.114H44.1l.079-8.392.843-1.619a.192.192,0,0,0,.019-.123L44.568,55.5a.343.343,0,0,0-.338-.282H42.663a.343.343,0,0,0-.338.282l-.475,3.482a.192.192,0,0,0,.019.123l.843,1.619.079,8.392H40.51a.375.375,0,0,0-.375.375v.239a1.727,1.727,0,0,0,.644,1.346c-.217.574-.556,1.559-.556,1.559V79.9a3.224,3.224,0,1,0,6.448,0V72.633s-.34-.986-.556-1.559a1.727,1.727,0,0,0,.644-1.346v-.239a.375.375,0,0,0-.375-.375Z'
                        transform='translate(-17.577 -14.94)'
                        fill='#8d8ea3'
                      />
                      <path
                        id='Path_99'
                        data-name='Path 99'
                        d='M71.038,59.453l-.111-.059v5.679l-1.1.758H66.492l-1.1-.758V59.394l-.111.059A6.676,6.676,0,0,0,65.86,71.5a2.333,2.333,0,0,0-.038.409V85.25h1.591V76.734a1.488,1.488,0,0,1,.154-.06,1.842,1.842,0,0,1,.591-.1h0a1.843,1.843,0,0,1,.591.1,1.492,1.492,0,0,1,.154.06V85.25H70.5V71.91a2.326,2.326,0,0,0-.038-.409,6.676,6.676,0,0,0,.578-12.047Z'
                        transform='translate(-28.592 -17.069)'
                        fill='#8d8ea3'
                      />
                    </g>
                  </svg>
                </div>
                <h5 className='font-weight-bold text-left relative p17'>
                  Support & Maintenance
                </h5>
                <p className='my-2 align-items-center lh-2 text-left relative p18'>
                  Requirement Gathering is the first and very important part of
                  our mobile app development process.
                </p>
              </div>
            </div>
          </div>

          {/* <div className="col-10 mx-auto mb-xl-5 mb-llg-9 font-clr-blue">
          Stop wasting time and money on technology.
          <a href="#" target="_blank" className="h-n-link  font-weight-bold">
            Explore our company
          </a>
        </div> */}
          <div id='qoute' className='mb-xl-5 pb-xxl-5 pt--75 col-10'></div>
          <div className='mb-xl-5 pb-xl-5 pt--80 col-10 '></div>
        </div>
      </div>
    </div>
  )
}

export default Process
